/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty-pattern */
import Web3 from "web3";
import ERC20ABI from "../contracts/ERC20ABI.json";
import xLEOABI from "../contracts/xLEOABI.json"

declare let window: any;

export const approveLEO = async (_pid: number, account: string, ContractAddress: string) => {
    if(!account) return;
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const tokencontract = await new window.web3.eth.Contract(ERC20ABI, process.env.REACT_APP_LEO_CONTRACT_ADDR);
    return await tokencontract.methods.approve(ContractAddress, '0x99999999999999999999999999999').send({ from: account });
}

export const getAllowanceLEO = async(_pid: number, account: string,  ContractAddress: string) => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }

    const tokencontract = await new window.web3.eth.Contract(ERC20ABI,  process.env.REACT_APP_LEO_CONTRACT_ADDR);
    return await tokencontract.methods.allowance(account, ContractAddress).call();
}

export const approvexLEO = async (_pid: number, account: string, ContractAddress: string) => {
    if(!account) return;
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const tokencontract = await new window.web3.eth.Contract(xLEOABI, process.env.REACT_APP_XLEO_CONTRACT_ADDR);
    return await tokencontract.methods.approve(ContractAddress, '0x99999999999999999999999999999').send({ from: account });
}

export const getAllowancexLEO = async(_pid: number, account: string,  ContractAddress: string) => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }

    const tokencontract = await new window.web3.eth.Contract(xLEOABI,  process.env.REACT_APP_XLEO_CONTRACT_ADDR);
    return await tokencontract.methods.allowance(account, ContractAddress).call();
}


export const depositLEO = async (amount: number, account: string) => {

    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }

    const tokencontract = await new window.web3.eth.Contract(ERC20ABI,  process.env.REACT_APP_LEO_CONTRACT_ADDR);
    const Decimal = await tokencontract.methods.decimals().call();
    // await tokencontract.methods.approve(process.env.REACT_APP_CONTRACT_ADDR, '0x' + (Math.round(amount * Math.pow(10, Decimal))).toString(16)).send({ from: account });
    const contract = await new window.web3.eth.Contract(xLEOABI, process.env.REACT_APP_XLEO_CONTRACT_ADDR);
    contract.methods.enter(Math.round(amount * Math.pow(10, Decimal))).send({ from: account });
}

export const burnxLEO = async (amount: number, account: string) => {

    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }

    const tokencontract = await new window.web3.eth.Contract(xLEOABI,  process.env.REACT_APP_XLEO_CONTRACT_ADDR);
    const Decimal = await tokencontract.methods.decimals().call();
    // await tokencontract.methods.approve(process.env.REACT_APP_CONTRACT_ADDR, '0x' + (Math.round(amount * Math.pow(10, Decimal))).toString(16)).send({ from: account });
    const contract = await new window.web3.eth.Contract(xLEOABI, process.env.REACT_APP_XLEO_CONTRACT_ADDR);
    contract.methods.leave(Math.round(amount * Math.pow(10, Decimal))).send({ from: account });
}

export const getUserLEOBalance = async (_pid: number, account: string) => {

    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const tokencontract = await new window.web3.eth.Contract(ERC20ABI, process.env.REACT_APP_LEO_CONTRACT_ADDR);
    const Decimal = await tokencontract.methods.decimals().call();
    const balance = await tokencontract.methods.balanceOf(account).call();
    return {_balance: balance / Math.pow(10, Decimal)};
}

export const getUserxLEOBalance = async (_pid: number, account: string) => {

    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const tokencontract = await new window.web3.eth.Contract(xLEOABI, process.env.REACT_APP_XLEO_CONTRACT_ADDR);
    const Decimal = await tokencontract.methods.decimals().call();
    const balance = await tokencontract.methods.balanceOf(account).call();
    return {balance: balance / Math.pow(10, Decimal)};
}

export const xLEOForLEO = async (xLeoAmount: number) => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const tokencontract = await new window.web3.eth.Contract(xLEOABI, process.env.REACT_APP_XLEO_CONTRACT_ADDR);
    const rate = await tokencontract.methods.xLEOForLEO(xLeoAmount).call();
    return rate;
}

export const LEOForxLEO = async (LeoAmount: number) => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const tokencontract = await new window.web3.eth.Contract(xLEOABI, process.env.REACT_APP_XLEO_CONTRACT_ADDR);
    const rate = await tokencontract.methods.LEOForxLEO(LeoAmount).call();
    return rate;
}

export const LEOBalance = async (account: string) => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const tokencontract = await new window.web3.eth.Contract(xLEOABI, process.env.REACT_APP_XLEO_CONTRACT_ADDR);
    const _balance = await tokencontract.methods.LEOBalance(account).call();
    return {_balance}
}

export const getTotalStaked = async () => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const LEO = await new window.web3.eth.Contract(xLEOABI, process.env.REACT_APP_LEO_CONTRACT_ADDR);
    const TVL = await LEO.methods.balanceOf(process.env.REACT_APP_XLEO_CONTRACT_ADDR).call();
    return {_TVL:TVL};
}

export const getTotalBurned = async () => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const xLEO = await new window.web3.eth.Contract(xLEOABI, process.env.REACT_APP_XLEO_CONTRACT_ADDR);
    const TVLburned = await xLEO.methods.balanceOf("0x000000000000000000000000000000000000dEaD").call();
    return {_TVLburned:TVLburned};
}