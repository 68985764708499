import { Box } from "@mui/system";
import React from "react";
import Paper from "./Paper";

const Bottombar = () => {
    return <Box display="flex" flexDirection="column" py="30px">
        <Paper width="300px" color="#FCF686" fontSize="20px" mb ="30px" style={{ cursor: 'pointer' }}hover={true}>
            <a href="https://traderjoexyz.com/trade?outputCurrency=0x945a6869819cdAD404DC80647a2085957CB1f28b#/" rel="noreferrer" target="_blank" style={{ color: 'white', textDecoration: 'none' }}>
            BUY $LEO ON TRADER JOE
            </a>
        </Paper>
        <Paper width="300px" color="#FCF686" fontSize="20px" style={{ cursor: 'pointer' }}hover={true}>
            <a href="https://app.bogged.finance/avax/swap?tokenIn=AVAX&tokenOut=0x945a6869819cdAD404DC80647a2085957CB1f28b" rel="noreferrer" target="_blank" style={{ color: 'white', textDecoration: 'none' }}>
            BUY $LEO ON BOGSWAP
            </a>
        </Paper>
    </Box>
}

export default Bottombar;