import axios from 'axios'
import _ from 'lodash'

export const SAFUU_ID = "safuu";
export const WFTM_ID = "wrapped-fantom"
export const WAVAX_ID = "wrapped-avax"
export const ETH_ID = "ethereum"
const LEOadd = process.env.REACT_APP_LEO_CONTRACT_ADDR;

export const getTokenPrice = async (tokenId: string) => {
    const res = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=${tokenId}&vs_currencies=usd`)
    const val = _.get(res.data, [tokenId, 'usd']);
    return val
}

export const getValueOfTreasury = async (treasuryAddr: string) => {
    const res = await axios.get(`https://api.covalenthq.com/v1/43114/address/${treasuryAddr}/balances_v2/?quote-currency=USD&format=JSON&nft=false&no-nft-fetch=false&key=ckey_6cd616c30ff1407bbbb4b12c5bd`);
    const val = res.data.data.items
    let price = 0;
    _.map(val, each=>{
        if(each.contract_address === LEOadd) {
            price += parseInt(each.balance) / Math.pow(10, each.contract_decimals) * each.quote_rate
        }
        price += each.quote;
    })
    return price
}

export const getValueOfInsuranceFund = async (insuranceFund: string) => {
    const res = await axios.get(`https://api.covalenthq.com/v1/43114/address/${insuranceFund}/balances_v2/?quote-currency=USD&format=JSON&nft=false&no-nft-fetch=false&key=ckey_6cd616c30ff1407bbbb4b12c5bd`);
    const val = res.data.data.items
    let price = 0;
    _.map(val, each=>{
        if(each.contract_address === LEOadd) {
            price += parseInt(each.balance) / Math.pow(10, 5) * each.quote_rate
        }
        price += each.quote;
    })
    return price
}

export const getValueOfPoolVal = async (pairAddr: string) => {
    const res = await axios.get(`https://api.covalenthq.com/v1/43114/address/${pairAddr}/balances_v2/?quote-currency=USD&format=JSON&nft=false&no-nft-fetch=false&key=ckey_6cd616c30ff1407bbbb4b12c5bd`);
    const val = res.data.data.items
    let price = 0;
    _.map(val, each=>{
        if(each.contract_address === LEOadd) {
            price += parseInt(each.balance) / Math.pow(10, 5) * each.quote_rate
        }
        price += each.quote;
    })
    return price
}

export const NumberFormat = (val: any) => {
    return val.toLocaleString('en-us', { minimumFractionDigits: 2 })
}