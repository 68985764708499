import { Box } from "@mui/material";
import React from "react";

const Distribution = () => {
    return <Box style={{background: 'url(/image/bg2.png)', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat'}} width="100%" display="flex" justifyContent="center">
        <Box mt="86px" mb="100px" display="flex" justifyContent="center" width="100%" flexDirection="column"  alignItems="center">
            <Box fontSize="30px" lineHeight="65px" mb="35px" color="#6EF47A">TOKEN DISTRIBUTION</Box>
            <Box display="flex" ml="55px">
                <Box display="flex" flexDirection="column" mr="15px" alignItems="center">
                    <Box border="1px solid #FCF686" width="12px" height="12px" borderRadius={999} />
                    <Box width="1px" bgcolor="#fcf686" height="26px" />
                    <Box border="1px solid #FCF686" width="12px" height="12px" borderRadius={999} />
                    <Box width="1px" bgcolor="#fcf686" height="26px" />
                    <Box border="1px solid #FCF686" width="12px" height="12px" borderRadius={999} />
                    <Box width="1px" bgcolor="#fcf686" height="26px" />
                    <Box border="1px solid #FCF686" width="12px" height="12px" borderRadius={999} />
                </Box>
                <Box lineHeight="16px" fontSize="12px" width="400px">
                    <Box mb="24px">30,000 tokens for whitelist presale at 10 tokens for 1 AVAX(burned if not sold).</Box>
                    <Box mb="24px">27,000 tokens for public presale at 9 tokens for 1 AVAX((burned if not sold).).</Box>
                    <Box mb="24px">30,000 tokens for liquidity addition. </Box>
                    <Box mb="24px">220,000 tokens for liquidity incentives.</Box>
                    <Box mb="24px">0 tokens for team. (We will pay team with part of treasury funds)</Box>
                </Box>
            </Box>
        </Box>
    </Box>
}

export default Distribution;