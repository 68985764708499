import { Box } from "@mui/material";
import React from "react";
import arrow2 from "../../assets/images/Arrow2.svg"
import arrow3 from "../../assets/images/Arrow3.svg"
import arrow1 from "../../assets/images/Arrow1.svg"
import logo from "../../assets/images/logo-large.png"
import kyc from "../../assets/images/assure2.png"
import { Button } from "../Global";
import useMobile from "../../hooks/useMobile";

const Main = () => {
    const { isMobile } = useMobile();
    return <Box style={{ background: 'url(/image/mainbg.png)', backgroundSize: '100% 100%' }} width="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        <Box mt={isMobile ? "70px" : "150px"} mb="50px" display="flex" flexDirection={isMobile ? 'column-reverse' : 'row'} px="60px">
            <Box display="flex" flexDirection="column" mr={isMobile ? "0" : "70px"} mt={isMobile ? "100px" : "0"} >
                <Box display="flex" flexDirection="column" fontSize={isMobile ? "30px" : "50px"} fontWeight="500" lineHeight={isMobile ? "50px" : "70px"} mb="40px">
                    <Box component="img" src={arrow2} ml="55px" width={isMobile ? "150px" : "250px"} />
                    <Box display="flex" alignItems={'center'}>
                        <Box component="img" src={arrow3} width="35px" mr="20px" />
                        <Box>Liquidity Node</Box>
                    </Box>
                    <Box display="flex" alignItems={'center'}>
                        <Box component="img" src={arrow3} width="35px" mr="20px" />
                        <Box>Auto-Compounding Protocol</Box>
                    </Box>
                    <Box display="flex" alignItems={'center'}>
                        <Box component="img" src={arrow3} width="35px" mr="20px" />
                        <Box>Smartcoin double,double</Box>
                    </Box>
                    <Box display="flex" alignItems={'center'}>
                        <Box component="img" src={arrow3} width="35px" mr="20px" />
                        <Box>Income Generation</Box>
                    </Box>
                </Box>
                <Box display="flex" ml="55px">
                    <Box display="flex" flexDirection="column" mr="25px">


                        <Box display="flex" lineHeight="28px" fontSize={isMobile ? "12px" : "18px"} maxWidth="580px" fontWeight="500">
                            <Box display="flex" alignItems="center" flexDirection="column" mr="30px">
                                <Box border="2px solid #FCF686" width="28px" height="28px" borderRadius={'50%'} />
                                <Box width="2px" bgcolor="#fcf686" height=" -webkit-fill-available" />
                            </Box>
                            <Box pb="28px">Liquidity Node: lock your lp forever as a node to ROI in 7-15 days </Box>
                        </Box>
                        <Box display="flex" lineHeight="28px" fontSize={isMobile ? "12px" : "18px"} maxWidth="580px" fontWeight="500">
                            <Box display="flex" alignItems="center" flexDirection="column" mr="30px">
                                <Box border="2px solid #FCF686" width="28px" height="28px" borderRadius={'50%'} />
                                <Box width="2px" bgcolor="#fcf686" height=" -webkit-fill-available" />
                            </Box>
                            <Box pb="28px">xLEO: yielding rebase APY + Buy back APR</Box>
                        </Box>
                        <Box display="flex" lineHeight="28px" fontSize={isMobile ? "12px" : "18px"} maxWidth="580px" fontWeight="500">
                            <Box display="flex" alignItems="center" flexDirection="column" mr="30px">
                                <Box border="2px solid #FCF686" width="28px" height="28px" borderRadius={'50%'} />
                                <Box width="0px" bgcolor="" height=" -webkit-fill-available" />
                            </Box>
                            <Box pb="28px">Profit Generation: A Part of Trading Fees + Treasury Profit Generation Distributed to Protocol Participants by staking xLEO</Box>
                        </Box>
                        <Box display="flex" lineHeight="28px" fontSize={isMobile ? "12px" : "18px"} maxWidth="580px" fontWeight="500">
                            <Box display="flex" alignItems="center" flexDirection="column" mr="30px">
                                <Box border="2px solid #FCF686" width="28px" height="28px" borderRadius={'50%'} />
                                <Box width="2px" bgcolor="#fcf686" height=" -webkit-fill-available" />
                            </Box>
                            <Box pb="28px">Holding: High Autocompounding Fixed APY in Crypto – 383,025.80%</Box>
                        </Box>
                    </Box>
                    <Box >
                    </Box>
                </Box>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
                <Box component="img" src={logo} width={isMobile ? "150px" : "250px"} mb="10px" />
                <Box fontSize={isMobile ? "20px" : "40px"} lineHeight={isMobile ? "32px" : "65px"} mb="20px" fontWeight="500">LEONIDAS FINANCE</Box>
                <Button style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', width:"280px", justifyContent: 'center' }}>
                    <Box component="img" src={arrow1} mr="5px"  />
                    <a href="https://traderjoexyz.com/trade?outputCurrency=0x945a6869819cdAD404DC80647a2085957CB1f28b#/" target ="blank" style={{textDecoration: 'none', color: 'inherit'}}>BUY $LEO ON TRADER JOE</a>
                </Button>
                <Button style={{ display: 'flex', alignItems: 'center', width:"280px", justifyContent: 'center' }}>
                    <Box component="img" src={arrow1} mr="5px"  />
                    <a href=" https://app.bogged.finance/avax/swap?tokenIn=AVAX&tokenOut=0x945a6869819cdAD404DC80647a2085957CB1f28b" target ="blank" style={{textDecoration: 'none', color: 'inherit'}}>BUY $LEO ON BOGSWAP</a>
                </Button>
            </Box>
        </Box>
        <Box display="flex" flexDirection="column" mr={isMobile ? "0" : "70px"} mt={isMobile ? "100px" : "0"} >
            <Box display="flex" flexDirection="column" alignItems="center">
            <Box fontSize="75px" lineHeight="22px" justifyContent="center" alignItems="center" mt="15px" color ="#E5C55A">KYC</Box>
                    <Box component="img" src={kyc} width={isMobile ? "150px" : "350px"} />
            </Box>
        </Box>

    </Box>
}

export default Main;