import { Box } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import Topbar from "../Components/Topbar";
import LEO_ABI from '../contracts/LEO_ABI.json'
import Web3 from "web3";
import { getValueOfTreasury, getValueOfInsuranceFund, getValueOfPoolVal } from "../hooks/getDataFromApi";
import { getLPPrice } from "../hooks/useTokenInfo";
import { getTotalBurned, LEOBalance } from "../hooks/xLEOinfo";
import Sidebar from "../Components/Sidebar"
import Paper from "../Components/Paper";
import CountUp from 'react-countup';
import useMobile from "../hooks/useMobile";
import useAuth from "../hooks/useAuth";
import Bottombar from "../Components/Bottombar";

declare let window: any;

const Dashboard: FC = () => {
    const decimal = 5;
    const [LEOPrice, setLEOPrice] = useState({oldVal: 0, newVal: 0})
    const [totalSupply, setTotalSupply] = useState({oldVal: 0, newVal: 0})
    const [circulatingSupply, setcirculatingSupply] = useState({oldVal: 0, newVal: 0})
    const [valueOfFirePit, setValueOfFirePit] = useState({oldVal: 0, newVal: 0})
    const [lastUpdatedTime, setLastUpdatedTime] = useState<number>()
    const [treasury, setTreasury] = useState({oldVal: 0, newVal: 0})
    const [insuranceFund, setInsuranceFund] = useState({oldVal: 0, newVal: 0})
    const [LeoInFarm, setLeoInFarm] = useState({oldVal: 0, newVal: 0})
    const [LeoInPresale, setLeoInPresale] = useState({oldVal: 0, newVal: 0})
    const [poolVal, setPoolVal] = useState({oldVal: 0, newVal: 0})
    const [timer, setTimer] = useState<any>(0)
    const [period, setPeriod] = React.useState<number>(-1)
    const {isMobile} = useMobile()
    const { account } = useAuth()
    const [Burned, setBurned] = useState({oldVal: 0, newVal: 0});
    const [LeoBurned, setLeoBurned] = useState({oldVal: 0, newVal: 0});

    useEffect(() => {
        setPeriod(1);
        init(true)
        console.log("init")
        
        // eslint-disable-next-line
    }, [account])
    useEffect(() => {
        console.log("start")
        clearInterval(timer);
        const time = setInterval(() => {
            if (!lastUpdatedTime) return
            if (lastUpdatedTime < 0) {
                init(false)
            }
            if(period%10 === 0) {

                init(false)
            }
            getTotalBurned().then(({_TVLburned}) =>{
                setBurned({oldVal:Burned.newVal,newVal:_TVLburned})
            });

            LEOBalance("0x000000000000000000000000000000000000dEaD").then(({_balance})=>{
                setLeoBurned({oldVal: LeoBurned.newVal, newVal:_balance/100000})
            });

            setLastUpdatedTime((timeLimit: any) => (timeLimit - 1));
            setPeriod((period) => period + 1);
        }, 1000);
        setTimer(time)
        // eslint-disable-next-line
    }, [lastUpdatedTime]);

    

    const init = async (timerFlag: boolean) => {

        console.log("here");
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum);
        }
        
        const LEO_contract = new window.web3.eth.Contract(LEO_ABI as any, process.env.REACT_APP_LEO_CONTRACT_ADDR);
        const lastRebasedTime = await LEO_contract.methods._lastRebasedTime().call();
        console.log(lastRebasedTime);
        let rebaseTime =  15*60 - (Math.floor(Date.now() / 1000) - lastRebasedTime)%(15*60)
        setLastUpdatedTime(rebaseTime)
        if(timerFlag) {
            clearTimeout(timer); 
        }
        const _totalSupply = await LEO_contract.methods.totalSupply().call();
        const _circulatingSupply = await LEO_contract.methods.getCirculatingSupply().call();
        const LEOinPresale = await LEO_contract.methods.balanceOf(process.env.REACT_APP_PRESALE_CONTRACT_ADDR).call()/100000;
        const treasuryAddr = await LEO_contract.methods.treasuryReceiver().call();
        const insuranceFundAddr = await LEO_contract.methods.LeoInsuranceFundReceiver().call();
        const firePitAddr = await LEO_contract.methods.firePit().call();
        const pairAddr = await LEO_contract.methods.pairAddress().call();

        setLeoInPresale({oldVal:LeoInPresale.newVal, newVal: LEOinPresale});

        setLEOPrice({oldVal: LEOPrice.newVal, newVal: (await getLPPrice(0))._LeoPrice});
        setLeoInFarm({oldVal:LeoInFarm.oldVal, newVal:(await getLPPrice(0))._LEOstaked});
        setTotalSupply({oldVal: totalSupply.newVal, newVal: parseFloat(_totalSupply) / (10 ** decimal)})
   
        setValueOfFirePit({oldVal: valueOfFirePit.newVal, newVal: await LEO_contract.methods.balanceOf(firePitAddr).call() / (10 ** decimal)})
        setTreasury({oldVal: treasury.newVal, newVal: await getValueOfTreasury(treasuryAddr)})
        setInsuranceFund({oldVal: insuranceFund.newVal, newVal: await getValueOfInsuranceFund(insuranceFundAddr)})
        setPoolVal({oldVal: poolVal.newVal, newVal: await getValueOfPoolVal(pairAddr)})
        
        console.log(LeoInFarm);
        setcirculatingSupply({oldVal: circulatingSupply.newVal, newVal:(_circulatingSupply/ (10 ** decimal)) })
    }

    return <Box
        display="flex"
        flexDirection="column"
        width="100%"
        style={{background: 'url(/image/bg4.png)', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat'}}
        zIndex="0"
    >
        <Topbar mode="staking" />
        <Box display="flex" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
            <Sidebar />
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="center"
                width="75%"
                ml={isMobile ? "auto":"300px"}
                mr="auto"
                zIndex="5"
            >
                <Paper display="flex" flexDirection="column" mt="50px" mb="15px" width="70%" p="15px" >
                    <Box display="flex" justifyContent="center" alignItems="center" width="100%" mb="20px" flexWrap={'wrap'}>
                        <Box flex={1} display="flex" flexDirection="column" justifyContent={'center'} alignItems="center" mb="20px" width="100%" minWidth="180px">
                            <Box mb="5px" fontSize="18px" style={{textTransform: 'uppercase'}}>LEO PRICE</Box>
                            <Box fontSize="24px" color="#6EF47A">$
                                <CountUp start={LEOPrice.oldVal } decimal="." decimals={3} end={LEOPrice.newVal}
                                    useEasing={true} duration={1.2} />
                            </Box>
                        </Box>
                        <Box flex={1} display="flex" flexDirection="column" justifyContent={'center'} alignItems="center" mb="20px" width="100%" minWidth="180px">
                            <Box mb="5px" fontSize="18px"  style={{textTransform: 'uppercase'}}>Market Cap</Box>
                            <Box fontSize="24px" color="#6EF47A">$
                                <CountUp start={LEOPrice.oldVal * (totalSupply.oldVal- LeoInFarm.oldVal-LeoBurned.oldVal-LeoInPresale.oldVal) } decimal="." decimals={3} end={LEOPrice.newVal *( totalSupply.newVal- LeoInFarm.newVal-LeoBurned.newVal-LeoInPresale.newVal)}
                                    useEasing={true} duration={1.2} />
                            </Box>
                        </Box>
                        <Box flex={1} display="flex" flexDirection="column" justifyContent={'center'} alignItems="center" mb="20px" width="100%" minWidth="180px">
                            <Box mb="5px" fontSize="18px"  style={{textTransform: 'uppercase'}}>Circulating Supply</Box>
                            <Box fontSize="24px" color="#6EF47A">
                                <CountUp start={circulatingSupply.oldVal - LeoInFarm.oldVal-LeoBurned.oldVal-LeoInPresale.oldVal} decimal="." decimals={3} end={circulatingSupply.newVal- LeoInFarm.newVal-LeoBurned.newVal-LeoInPresale.newVal}
                                    useEasing={true} duration={1.2} /></Box>
                        </Box>
                    </Box>
                    <Box display="flex" width="100%" justifyContent="center" alignItems="center" flexWrap={'wrap'}>
                        <Box flex={1} display="flex" flexDirection="column" justifyContent={'center'} alignItems="center" mb="20px" width="100%" minWidth="180px">
                            <Box mb="5px" fontSize="18px"  style={{textTransform: 'uppercase'}}>Backed Liquidity</Box>
                            <Box fontSize="24px" color="#6EF47A">100%</Box>
                        </Box>
                        <Box flex={1} display="flex" flexDirection="column" justifyContent={'center'} alignItems="center" mb="20px" width="100%" minWidth="180px">
                            <Box mb="5px" fontSize="18px"  style={{textTransform: 'uppercase'}}>Next Rebase</Box>
                            <Box fontSize="24px" color="#6EF47A">
                                {lastUpdatedTime && (~~((lastUpdatedTime - 1) / 3600) % 24).toLocaleString("en-US", {
                                    minimumIntegerDigits: 2,
                                    useGrouping: false,
                                })}
                                :
                                {lastUpdatedTime && (~~((lastUpdatedTime - 1) / 60) % 24).toLocaleString("en-US", {
                                    minimumIntegerDigits: 2,
                                    useGrouping: false,
                                })}
                                :
                                {lastUpdatedTime && (((lastUpdatedTime - 1) % 60).toLocaleString("en-US", {
                                    minimumIntegerDigits: 2,
                                    useGrouping: false,
                                }))}
                            </Box>
                        </Box>
                        <Box flex={1} display="flex" flexDirection="column" justifyContent={'center'} alignItems="center" mb="20px" width="100%" minWidth="180px">
                            <Box mb="5px" fontSize="18px"  style={{textTransform: 'uppercase'}}>Total Supply</Box>
                            <Box fontSize="24px" color="#6EF47A">
                                <CountUp start={totalSupply.oldVal } decimal="." decimals={3} end={totalSupply.newVal}
                                    useEasing={true} duration={1.2} />
                            </Box>
                        </Box>
                    </Box>
                </Paper>
                <Box mb="15px" width="75%" display="flex" flexWrap={'wrap'}>
                    <Box display="flex" flex={1} mr="10px" mb="20px" minWidth="230px">
                        <Paper width="100%" p="15px">
                            <Box mb="5px" fontSize="18px"  style={{textTransform: 'uppercase'}}>LEO Price</Box>
                            <Box fontSize="24px" color="#6EF47A">$
                                <CountUp start={LEOPrice.oldVal } decimal="." decimals={3} end={LEOPrice.newVal}
                                    useEasing={true} duration={1.2} />
                            </Box>
                        </Paper>
                    </Box>
                    <Box display="flex" flex={1} justifyContent={'center'} mb="20px" minWidth="230px">
                        <Paper width="100%" p="20px">
                            <Box mb="5px" fontSize="18px" justifyContent={'center'}  alignItems="center"   style={{textTransform: 'uppercase'}}>Market Value of Treasury Asset</Box>
                            <Box fontSize="24px" color="#6EF47A">$
                                <CountUp start={treasury.oldVal } decimal="." decimals={3} end={treasury.newVal}
                                    useEasing={true} duration={1.2} />
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box mb="15px" width="75%" display="flex" flexWrap={'wrap'}>
                    <Box display="flex" flex={1} mr="10px" mb="20px" minWidth="230px">
                        <Paper width="100%" p="15px" alignItems="center" >
                            <Box mb="5px" fontSize="18px"  style={{textTransform: 'uppercase'}}>Pool Value</Box>
                            <Box fontSize="24px" color="#6EF47A">$
                                <CountUp start={poolVal.oldVal } decimal="." decimals={3} end={poolVal.newVal}
                                    useEasing={true} duration={1.2} />
                            </Box>
                        </Paper>
                    </Box>
                    <Box display="flex" flex={1} mr="10px" mb="20px" minWidth="190px">
                        <Paper width="100%" p="15px">
                            <Box mb="5px" fontSize="18px"  style={{textTransform: 'uppercase'}}>LEO INCOME GENERATION POOL VALUE</Box>
                            <Box fontSize="24px" color="#6EF47A">$
                                <CountUp start={insuranceFund.oldVal } decimal="." decimals={3} end={insuranceFund.newVal}
                                    useEasing={true} duration={1.2} />
                            </Box>
                        </Paper>
                    </Box>
                </Box>

                <Box mb="15px" width="75%" display="flex" flexWrap={'wrap'}>
                    <Box display="flex" flex={1} mr="10px" mb="20px" minWidth="190px">
                        <Paper width="100%" p="15px">
                            <Box mb="5px" fontSize="18px"  style={{textTransform: 'uppercase'}}># Value of FirePit</Box>
                            <Box fontSize="24px" color="#6EF47A">
                                <CountUp start={valueOfFirePit.oldVal+LeoBurned.oldVal } decimal="." decimals={3} end={valueOfFirePit.newVal+LeoBurned.newVal}
                                    useEasing={true} duration={1.2} />
                                LEO</Box>
                        </Paper>
                    </Box>
                    <Box display="flex" flex={1} mr="10px" mb="20px" minWidth="190px">
                        <Paper width="100%" p="15px">
                            <Box mb="5px" fontSize="18px"  style={{textTransform: 'uppercase'}}>$ Value of FirePit</Box>
                            <Box fontSize="24px" color="#6EF47A">$
                                <CountUp start={(valueOfFirePit.oldVal+LeoBurned.oldVal)*LEOPrice.oldVal } decimal="." decimals={3} end={(valueOfFirePit.newVal+LeoBurned.newVal)*LEOPrice.newVal}
                                    useEasing={true} duration={1.2} />
                            </Box>
                        </Paper>
                    </Box>
                    <Box display="flex" flex={1} mb="20px" minWidth="190px">
                        <Paper width="100%" p="15px">
                            <Box mb="5px" fontSize="18px"  style={{textTransform: 'uppercase'}}>% FirePit : Supply</Box>
                            <Box fontSize="24px" color="#6EF47A">
                                <CountUp start={totalSupply.oldVal ? (valueOfFirePit.oldVal+LeoBurned.oldVal)/totalSupply.oldVal*100:0 } decimal="." decimals={3} end={totalSupply.newVal?(valueOfFirePit.newVal+LeoBurned.newVal)/totalSupply.newVal*100:0 }
                                    useEasing={true} duration={1.2} />%
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Bottombar />
            </Box>
        </Box>

    </Box>
}
export default Dashboard;