import { Box, styled } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import Topbar from "../Components/Topbar";
import DepositIncomeDialogETH from "../Components/DepositIncomeDialogETH";
import { getAllowance, getStakedAmountAndLockedPeriod, approve, getPendingAvax, getLP2, withdraw, getContract, getUserRemainLockTime, ABI } from "../hooks/incomeInfoETH";
import { getLPPrice } from "../hooks/useTokenInfo";
import { xLEOForLEO } from "../hooks/xLEOinfo";
import { getTokenPrice, WAVAX_ID, ETH_ID} from "../hooks/getDataFromApi";

import useAuth from "../hooks/useAuth";
import Paper from "../Components/Paper";
import Sidebar from "../Components/Sidebar";
import CountUp from 'react-countup';
import { displayDays, roundWithPrecision } from "../utils/calculation";
import coin1 from "../assets/images/eth.png"
import logo1 from "../assets/images/logo1.png"
import useMobile from "../hooks/useMobile";
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="standard" {...props} />;
});

const CustomBtn = styled((props: any) => <button {...props} />)`
    background: ${(props) => (props.disabled ? "#797293" : "linear-gradient(45deg, #1e073f 10%, #a634fb 100%)")};
    border: ${(props) => (props.disabled ? "none" : "solid 1px #a634fb")};
    color: ${(props) => (props.disabled ? "#b29da6" : "white")};
    border-radius: 15px;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
`

const Staking: FC = () => {
    const [openDlg, setOpenDlg] = useState({ open: false, title: '' })
    const [openStakeWindow, setOpenStakeWindow] = useState(false)
    const [balance, setBalance] = useState({ oldVal: 0, newVal: 0 });
    const [claimableVal, setClaimableVal] = useState({ oldVal: 0, newVal: 0 })
    const [Rate, setRate] = useState({ oldVal: 0, newVal: 0 })
    const { account } = useAuth()
    const [TVL, setTVL] = useState({ oldVal: 0, newVal: 0 })
    const [total, setTotal] = useState({ oldVal: 0, newVal: 0 })
    const [LeoPrice, setLeoPrice] = useState({ oldVal: 0, newVal: 0 })
    const [AvaxPrice, setAvaxPrice] = useState({ oldVal: 0, newVal: 0 })
    const [timer, setTimer] = React.useState<any>({})
    const [period, setPeriod] = React.useState<number>(-1)
    const [contract, setContract] = useState<any>({})
    const [ratio, setRatio] = useState({ oldVal: 0, newVal: 0 })
    const [unlockTime, setUnlockTime] = React.useState<number>(0)
    const { isMobile } = useMobile()

    useEffect(() => {
        setBalance({ oldVal: 0, newVal: 0 })
        getLPPrice(0).then(({ _LeoPrice }) => {
            setLeoPrice({ oldVal: LeoPrice.newVal, newVal: _LeoPrice })

        });

        xLEOForLEO(100000).then((rate: number) => {
            setRate({ oldVal: Rate.newVal, newVal: rate })
        });
        getAvaxP();
        getLP2(0).then(({ _TVL, _total }) => {

            setTVL({ oldVal: TVL.newVal, newVal: _TVL })
            setTotal({ oldVal: total.newVal, newVal: _total })
        });
        if (!account) return;
        getStakedAmountAndLockedPeriod(0, account).then(({ _balance, _ratio }) => {
            setBalance({ oldVal: balance.newVal, newVal: _balance })
            setRatio({ oldVal: ratio.newVal, newVal: _ratio })
        })
        getAllowance(0, account).then((isAllowance) => {
            if (isAllowance > 0) setOpenStakeWindow(true)
            else setOpenStakeWindow(false)
        })
        getPendingAvax(0, account).then((reward: number) => {
            setClaimableVal({ oldVal: claimableVal.newVal, newVal: reward / 1e18 });
        })
        setPeriod(0);
        getContract(ABI.StakingABI).then((contract: any) => { setContract(contract) })
        getUserRemainLockTime(0, account).then((unlockTime: number) => {
            setUnlockTime(Number(unlockTime))
        })
        // eslint-disable-next-line
    }, [account])

    const getApprove = async () => {
        const res = await approve(0, account || undefined)
        if (res) setOpenStakeWindow(true)
    }
    const getAvaxP = async () => {
        setAvaxPrice({ oldVal: AvaxPrice.newVal, newVal: await getTokenPrice(ETH_ID) });

    }

    React.useEffect(() => {
        clearTimeout(timer);
        const time = setTimeout(() => {
            if (period % 10 === 0) {
                getLPPrice(0).then(({ _LeoPrice }) => {
                    setLeoPrice({ oldVal: LeoPrice.newVal, newVal: _LeoPrice })

                });

                getAvaxP();

                getLP2(0).then(({ _TVL, _total }) => {
                    setTotal({ oldVal: total.newVal, newVal: _total })
                    setTVL({ oldVal: TVL.newVal, newVal: _TVL })
                });
                xLEOForLEO(100000).then((rate: number) => {
                    setRate({ oldVal: Rate.newVal, newVal: rate })
                });
                if (account) {
                    getPendingAvax(0, account).then((reward: number) => {
                        setClaimableVal({ oldVal: claimableVal.newVal, newVal: reward / 1e18 });
                    })
                    getUserRemainLockTime(0, account).then((unlockTime: number) => {
                        setUnlockTime(Number(unlockTime))
                    })
                    getStakedAmountAndLockedPeriod(0, account).then(({ _balance, _ratio }) => {
                        setBalance({ oldVal: balance.newVal, newVal: _balance })
                        setRatio({ oldVal: ratio.newVal, newVal: _ratio })

                    })
                }
            }
            if (account && unlockTime > 0) {
                setUnlockTime((period) => period - 1);
            }
            setPeriod((period) => period + 1);
        }, 1000);
        setTimer(time)
        // eslint-disable-next-line
    }, [period]);

    const claim = async () => {
        await withdraw(0, 0, account || "");
        contract.events.Withdraw({
            filter: { user: account, pid: 0 }, // Using an array means OR: e.g. 20 or 23
            // fromBlock: 0
        })
            .on('data', function (event: any) {
                setClaimableVal({ oldVal: claimableVal.newVal, newVal: 0 })
            })
            .on('changed', function (event: any) {
                // remove event from local database
            })
            .on('error', console.error);
    }

    const onEvnet = () => {
        if (!account) return;
        contract.events.allEvents({
            filter: { user: account, pid: 0 }, // Using an array means OR: e.g. 20 or 23
            // fromBlock: 0
        })
            .on('data', function (event: any) {
                console.log("evnet trigger", event)
                getStakedAmountAndLockedPeriod(0, account).then(({ _balance }) => {
                    setBalance({ oldVal: balance.newVal, newVal: _balance })
                })
                getPendingAvax(0, account).then((reward: number) => {
                    setClaimableVal({ oldVal: claimableVal.newVal, newVal: reward / 1e18 });
                })
                getUserRemainLockTime(0, account).then((unlockTime: number) => {
                    setUnlockTime(Number(unlockTime))
                })
            })
            .on('changed', function (event: any) {
            })
            .on('error', console.error);
    }

    return <Box
        display="flex"
        flexDirection="column"
        width="100%"
        style={{ background: 'url(/image/bg4.png)', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat' }}
        zIndex="0"
    >
        <Topbar mode="staking" />
        <Box display="flex" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
            <Sidebar />
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="center"
                width="75%"
                m="auto"
                ml={isMobile ? "auto" : "300px"}
                mb="auto"
                zIndex="5"
            >
                <Box display="flex" mt="10px" justifyContent={"center"} alignItems="center" flexDirection="column">
                    <Alert severity="warning" sx={{ width: '100%', mb: '10px' }}>
                        <Box display="flex" justifyContent={"center"} alignItems="center">
                            If you extend your lock, second apr boost will only apply to the second lock amount.
                        </Box>
                        <Box display="flex" justifyContent={"center"} alignItems="center">
                            For example, if you lock for 10 days for x amount and then 90 days for y amount, your boost will be x boost 10 days + y boost 90 days
                        </Box>
                    </Alert>
                </Box>
                <Box display="flex" justifyContent={"center"} alignItems="center" mt="30px" mb="30px" sx={{ flexDirection: { md: 'row', sm: 'column', xs: 'column' } }}>
                    <Paper p="15px" mr="20px" sx={{ mr: { md: '20px', sm: '0', xs: '0' }, mb: { md: '0', sm: '30px', xs: '30px' }, width: { md: 'auto', sm: '300px', xs: '300px' } }}>
                        <Box fontSize="16px" lineHeight="22px">Max APR(locked 90 days on first deposit)</Box>
                        <Box fontSize="24px" fontWeight={"500"} color="#FCF686">
                            <CountUp start={AvaxPrice.oldVal * 0.45 * 365 * 100 / (total.oldVal * LeoPrice.oldVal) * 2} decimal="." decimals={2} end={AvaxPrice.newVal * 0.45 * 365 * 100 / (total.newVal * LeoPrice.newVal) * 2}
                                useEasing={true} duration={1.2} />%
                        </Box>
                    </Paper>
                    <Paper p="15px" mr="20px" sx={{ mr: { md: '20px', sm: '0', xs: '0' }, mb: { md: '0', sm: '30px', xs: '30px' }, width: { md: 'auto', sm: '300px', xs: '300px' } }}>
                        <Box fontSize="16px" lineHeight="22px">Current Boosted APR</Box>
                        <Box fontSize="24px" fontWeight={"500"} color={ratio.newVal > 1 ? "#FCF686" : "#6EF47A"}>
                            <CountUp start={AvaxPrice.oldVal * 0.45 * 365 * 100 * ratio.oldVal / (total.oldVal * LeoPrice.oldVal)} decimal="." decimals={2} end={AvaxPrice.newVal * 0.45* 365 * 100 * ratio.newVal / (total.newVal * LeoPrice.newVal)}
                                useEasing={true} duration={1.2} />%
                        </Box>
                    </Paper>
                    <Paper p="15px" mr="20px" sx={{ mr: { md: '20px', sm: '0', xs: '0' }, mb: { md: '0', sm: '30px', xs: '30px' }, width: { md: 'auto', sm: '300px', xs: '300px' } }}>
                        <Box fontSize="16px" lineHeight="22px">Current Daily APR</Box>
                       
                        <Box fontSize="24px" fontWeight={"500"} color={ratio.newVal > 1 ? "#FCF686" : "#6EF47A"}>
                            <CountUp start={AvaxPrice.oldVal * 0.45 * 100 * ratio.oldVal / (total.oldVal * LeoPrice.oldVal)} decimal="." decimals={2} end={AvaxPrice.newVal * 0.45 * 100 * ratio.newVal / (total.newVal * LeoPrice.newVal)}
                                useEasing={true} duration={1.2} />%
                        </Box>
                    </Paper>
                    <Paper p="15px" mr="20px" sx={{ mr: { md: '20px', sm: '0', xs: '0' }, mb: { md: '0', sm: '30px', xs: '30px' }, width: { md: 'auto', sm: '300px', xs: '300px' } }}>
                        <Box fontSize="16px" lineHeight="22px">TVL</Box>
                        <Box fontSize="24px" fontWeight={"500"} color="#6EF47A">$
                            <CountUp start={roundWithPrecision(TVL.oldVal * LeoPrice.oldVal, 2)} decimal="." decimals={2} end={roundWithPrecision(TVL.newVal * LeoPrice.newVal, 2)}
                                useEasing={true} duration={1.2} />
                        </Box>
                    </Paper>
                </Box>
                <Box fontSize="25px" lineHeight="22px" justifyContent="center" alignItems="center">Locked Time:</Box>
                <Box display="flex" justifyContent="center" alignItems="center" mb="10px" mt="20px">
                    <Box color="#6EF47A" display="flex" flexDirection={'column'} alignItems="center" mr="40px">
                        <Box display="flex" mb="10px">
                            <Paper p="10px" mr="10px" borderRadius={"8px"}>{displayDays(unlockTime).day}</Paper>
                        </Box>
                        <Box>DAY</Box>
                    </Box>
                    <Box color="#6EF47A" display="flex" flexDirection={'column'} alignItems="center" mr="40px">
                        <Box display="flex" mb="10px">
                            <Paper p="10px" mr="10px" borderRadius={"8px"}>{displayDays(unlockTime).hour}</Paper>
                        </Box>
                        <Box>HOUR</Box>
                    </Box>
                    <Box color="#6EF47A" display="flex" flexDirection={'column'} alignItems="center" mr="40px">
                        <Box display="flex" mb="10px">
                            <Paper p="10px" mr="10px" borderRadius={"8px"}>{displayDays(unlockTime).minute}</Paper>
                        </Box>
                        <Box>MINUTE</Box>
                    </Box>
                    <Box color="#6EF47A" display="flex" flexDirection={'column'} alignItems="center">
                        <Box display="flex" mb="10px">
                            <Paper p="10px" mr="10px" borderRadius={"8px"}>{displayDays(unlockTime).second}</Paper>
                        </Box>
                        <Box>SECOND</Box>
                    </Box>

                </Box>

                <Box fontSize="25px" lineHeight="22px" justifyContent="center" mb="5px" mt="5px" alignItems="center">Warning: 2% deposit fees to burn xLEO!</Box>
                <Box display="flex" mb="30px" mt="30px" justifyContent={"center"} alignItems="center" width="60%" sx={{ flexDirection: { md: 'row', sm: 'column-reverse', xs: 'column-reverse' } }}>
                    <Paper p="20px" sx={{ mr: { md: '20px', sm: '0', xs: '0' }, mb: { md: '0', sm: '50px', xs: '50px' }, width: { md: 'auto', sm: '300px', xs: '300px' } }} flex={1} position="relative">
                        <Paper p="7px" display="flex" left="50%" top="-17px" style={{ transform: 'translateX(-50%)' }} position="absolute" alignItems="center" justifyContent="center" bgcolor="lightgray">
                            <Box component="img" src={coin1} width="20px" />
                        </Paper>
                        <Box fontSize="14px" lineHeight="26px">ETH EARNED</Box>
                        <Box fontSize="32px" fontWeight={"600"} lineHeight="50px" color="#FCF686" >
                            <CountUp start={claimableVal.oldVal} decimal="." decimals={7} end={claimableVal.newVal}
                                useEasing={true} duration={1.2} />
                        </Box>
                        <Box fontSize="16px" lineHeight="26px" fontWeight="500" mb="30px">USD
                            <span style={{ color: "#FCF686" }}>&nbsp; $
                                <CountUp start={(claimableVal.oldVal * AvaxPrice.oldVal)} decimal="." decimals={4} end={claimableVal.newVal * AvaxPrice.newVal}
                                    useEasing={true} duration={1.2} />
                            </span>
                        </Box>
                        <Paper width="100%" color="#FCF686" fontSize="24px" style={{ cursor: 'pointer' }} onClick={claim} hover={true}>CLAIM</Paper>
                    </Paper>
                    <Paper p="20px" sx={{ mr: { md: '20px', sm: '0', xs: '0' }, mb: { md: '0', sm: '50px', xs: '50px' }, width: { md: 'auto', sm: '300px', xs: '300px' } }} flex={1} position="relative">
                        <Box left="50%" top="-17px" style={{ transform: 'translateX(-50%)' }} position="absolute" display="flex" >
                            <Paper p="7px" ml="20x" bgcolor="black">
                                <Box component="img" src={logo1} width="20px" />
                            </Paper>
                        </Box>

                        <Box fontSize="14px" lineHeight="26px">xLEO STAKED</Box>
                        <Box fontSize="32px" fontWeight={"600"} lineHeight="50px" color="#FCF686" >
                            <CountUp start={balance.oldVal} decimal="." decimals={8} end={balance.newVal}
                                useEasing={true} duration={1.2} />
                        </Box>
                        <Box fontSize="16px" lineHeight="26px" fontWeight="500" mb="30px">USD
                            <span style={{ color: "#FCF686" }}>$
                                <CountUp start={LeoPrice.oldVal * Rate.oldVal * balance.oldVal / 100000} decimal="." decimals={2} end={LeoPrice.newVal * Rate.newVal * balance.newVal / 100000}
                                    useEasing={true} duration={1.2} />
                            </span>
                        </Box>
                        {!openStakeWindow ?
                            <Paper width="100%" color="#FCF686" fontSize="24px" style={{ cursor: 'pointer' }} hover={true} onClick={getApprove} >APPROVE</Paper> :
                            <Box display="flex" justifyContent="space-between" width="70%">
                                <Paper width="50px" onClick={() => { setOpenDlg({ open: true, title: 'Withdraw' }) }} style={{ cursor: 'pointer' }} hover={true}>-</Paper>
                                <CustomBtn style={{ width: "40px", display: 'flex', justifyContent: 'center', alignItems: 'center' }} disabled>
                                    <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{ color: "rgb(189, 189, 189)" }}><path d="M7 2v11h3v9l7-12h-4l4-8z"></path></svg>
                                </CustomBtn>
                                <Paper width="50px" onClick={() => { setOpenDlg({ open: true, title: 'Deposit' }) }} style={{ cursor: 'pointer' }} hover={true}>+</Paper>
                            </Box>
                        }
                    </Paper>
                </Box>


                <Box display="flex" mb="50px" justifyContent={"center"} alignItems="center" flexDirection="column">
                    <Alert severity="success" sx={{ width: '100%', mb: '100px' }}>
                        <Box display="flex" justifyContent={"center"} alignItems="center">
                            Your xLEOs are also acruing values(rebase and buy back)
                        </Box>
                    </Alert>
                </Box>
            </Box>

        </Box>


        <DepositIncomeDialogETH
            open={openDlg.open}
            title={openDlg.title}
            onClose={() => { setOpenDlg({ open: false, title: '' }) }}
            onEvent={onEvnet}
        />
    </Box>
}
export default Staking;