/* eslint-disable react/jsx-no-duplicate-props */
import { Box } from "@mui/material";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import { Routes as Router } from "./Routes";
import "./App.css";
import Roadmap from "./Components/HomeComponent/Roadmap";
import Account from "./Pages/Account";
import Calculator from "./Pages/Calculator";
import Dashboard from "./Pages/Dashboard";
import Home from "./Pages/Home";
import Presale from "./Pages/Presale";
// import _ from "lodash";
import Staking from "./Pages/Staking";
import XLeoWrap from "./Pages/XLeoWrap";
import Income from "./Pages/Income";
import IncomeETH from "./Pages/incomeETH";
import DappDashboard from "./Pages/DappDashboard";


const App: React.FC = () => {
  const renderMainPage = () => {
    return <BrowserRouter>
      <Box display="flex" height="100vh">
        <Routes>
          <Route>
            <Route path="/" element={<Home />} />
            <Route path="/staking" element={<Staking />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/account" element={<Account />} />
            <Route path="/calculator" element={<Calculator />} />
            <Route path="/presale" element={<Presale />} />
            <Route path="/wrap" element={<XLeoWrap />} />
            <Route path="/roadmap" element={<Roadmap/>} />
            <Route path="/income" element={<Income/>} />
            <Route path="/incomeeth" element={<IncomeETH/>} />
            <Route path="/dapp_dashboard" element={<DappDashboard/>} />
          </Route>
        </Routes>
      </Box>
    </BrowserRouter>
  }
  return renderMainPage()
};

export default App;
