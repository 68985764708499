import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Box, Slider } from '@mui/material';
import { styled } from '@mui/system';
import useAuth from '../hooks/useAuth';
import { deposit, getStakedAmountAndLockedPeriod, getUserBalance, withdraw, getUserRemainLockTime } from '../hooks/incomeInfo';
import { displayDays, toFixed } from '../utils/calculation';
import Paper from './Paper';

interface DepositDialogProps {
    open: boolean;
    onClose: () => void;
    title: string;
    onEvent?: () => void;
}

const PrettoSlider = styled(Slider)({
    color: '#52af77',
    height: 8,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 0,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#52af77',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});

const CustomInput = styled((props: any) => <input {...props} />)`
    border: none;
    height: 1.8vw;
    font-size: 1vw;
    background: transparent;
    color: white;
    &:focus-visible{
        outline: none;
        border: none;
    }
`

// const MaxLockTime = 90 * 24 * 60;
const MaxLockTime = 90*24*60;

export default function DepositIncomeDialog(props: DepositDialogProps) {
    const [open, setOpen] = React.useState(false);
    const { account } = useAuth()
    const [balance, setBalance] = React.useState(0);
    const [amount, setAmount] = React.useState("");
    const [minutes, setMinutes] = React.useState(0)
    const [poolId, setPoolId] = React.useState(0)
    const [period, setPeriod] = React.useState<number>(0)
    const [timer, setTimer] = React.useState<any>({})
    const [min, setMin] = React.useState(0);

    const handleSlider = (event: any, newValue: any) => {
        setMinutes(Math.max(newValue, min));
    };

    React.useEffect(() => {
        setOpen(props.open)
        if(props.open) {
            clearTimeout(timer);
            setAmount("0")
            setPoolId(0)
            setBalance(0)
            if(!account) return;
            if(props.title === "Deposit") {
                getUserBalance(0, account).then(({balance}) => {
                    
                    setBalance(balance)
                })
            } else {
                getStakedAmountAndLockedPeriod(0, account).then(({_balance}) => {
                    setBalance(_balance)
                })
            }
            getUserRemainLockTime(0, account).then((unlockTime: number)=>{
                setPeriod(Number(unlockTime))
            })
            // eslint-disable-next-line
        }
        // eslint-disable-next-line
    }, [props.open])

    React.useEffect(() => {
        clearTimeout(timer);
        const time = setTimeout(() => {
            if (period - 1 < 0) {
                clearTimeout(time);
                return;
            }
            setPeriod((period) => period - 1);
            setMin(Math.round(period/60));
        }, 1000);
        // eslint-disable-next-line
        setTimer(time)
        setMinutes(Math.max(Math.round(period/60), minutes))
        // eslint-disable-next-line
    }, [period]);

    const handleClose = () => {
        props.onClose();
        setOpen(false);
    };

    const depositOrWithdraw = async () => {
        if (!account) return;
        const val = parseFloat(amount)
        if(!val) {
            alert("Invalid amount")
            return;
        }
        if(props.title === "Deposit") {
            await deposit(poolId, val, minutes, account)
        } else {
            await withdraw(poolId, val, account);
        }
        props.onEvent&&props.onEvent()
        handleClose()
    }

    const onChangeDays = (e:any) => {
        if(props.title === "Withdraw") return;
        let day = Number(e.target.value);
        if(!day || day > 90) day = 0;
        const hour = (~~(minutes / 60)) % 24;
        const min = (~~(minutes % 60));
        setMinutes(Math.max(min+hour*60+day*60*24, min));
    } 
    const onChangeHour = (e:any) => {
        if(props.title === "Withdraw") return;
        let hour = Number(e.target.value);
        if(!hour || hour > 24) hour=0;
        const day = (~~(minutes / 60 / 24));
        const min = (~~(minutes % 60));
        setMinutes(Math.max(min+hour*60+day*60*24, min));
    } 
    const onChangeMin = (e:any) => {
        if(props.title === "Withdraw") return;
        let min = Number(e.target.value);
        if(!min || min > 60) min=0;
        const day = (~~(minutes / 60 / 24));
        const hour = ((~~(minutes / 60)) % 24);
        setMinutes(Math.max(min+hour*60+day*60*24,min));
    } 

    const changeAmount = (e: any) => {
        setAmount(e.target.value)
    }

    const setAmountPercentage = (percentage: number) => () => {
        setAmount(""+(balance * percentage / 100))
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                      background: 'url(/image/bg3.png)',
                      backgroundSize: '100% 100%',
                      backgroundRepeat: 'no-repeat',
                      color: 'white'
                    },
                }}
            >
                <Box fontSize="2vw" fontWeight="500" mt="2vw" px="2vw" pt="1vw" style={{textTransform: 'uppercase'}}>
                    {props.title}
                </Box>
                <Box display="flex" flexDirection="column" px="2vw" pt="2vw" pb="4vw">
                    <Box display="flex" mt="1vw" alignItems="flex-start" flexDirection="column" width="100%">
                        <Box flex={1} display="flex" flexDirection="column" mb="3vw" width="100%">
                            <Box fontSize="12px" mb="1vw" color="#C9C9C9">Amount</Box>
                            <Box borderBottom=" 1px solid white" display="flex" justifyContent="space-between">
                                <CustomInput value={amount} onChange={changeAmount} />
                                <Box color="white" display="flex" mb="0.5vw" >
                                    <Paper p="3px" mr="0.4vw" hover={true}><Button color="inherit" onClick={setAmountPercentage(25)} variant="text" sx={{ borderRadius: '5px', textTransform: 'none', fontSize: '0.6vw', p: '0.3vw', minWidth: '1vw' }}>25%</Button></Paper>
                                    <Paper p="3px" mr="0.4vw" hover={true}><Button color="inherit" onClick={setAmountPercentage(50)} variant="text" sx={{ borderRadius: '5px', textTransform: 'none', fontSize: '0.6vw', p: '0.3vw', minWidth: '1vw' }}>50%</Button></Paper>
                                    <Paper p="3px" mr="0.4vw" hover={true}><Button color="inherit" onClick={setAmountPercentage(75)} variant="text" sx={{ borderRadius: '5px', textTransform: 'none', fontSize: '0.6vw', p: '0.3vw', minWidth: '1vw' }}>75%</Button></Paper>
                                    <Paper p="3px" hover={true}><Button color="inherit" onClick={setAmountPercentage(100)} variant="text" sx={{ borderRadius: '5px', textTransform: 'none', fontSize: '0.6vw', p: '0.3vw', minWidth: '1vw' }}>MAX</Button></Paper>
                                </Box>
                            </Box>
                            <Box fontSize="12px" mt="1vw" textAlign="right" color="#C9C9C9">{props.title==="Deposit" ? "Available" : "Staked balance"}&nbsp; 
                                <span style={{ color: '#ED4529' }}>{Number(balance) ? toFixed(Number(balance)): 0}</span>
                            </Box>
                        </Box>
                    </Box>
                    <Box textAlign="center" mb="20px" color="#6EF47A">{props.title==="Deposit" ? "Choose your lock duration" : "Locked until"}</Box>
                    <Box mb="2vw" display="flex" justifyContent="center">
                        <Box display="flex" alignItems="center" mr="10px">
                            <CustomInput value={displayDays(props.title==="Withdraw"?period:minutes * 60).day} style={{width: '20px'}} onChange={onChangeDays} />
                            <Box ml="10px">days</Box>
                        </Box>
                        <Box display="flex" alignItems="center" mr="10px">
                            <CustomInput value={displayDays(props.title==="Withdraw"?period:minutes * 60).hour} style={{width: '20px'}} onChange={onChangeHour} />
                            <Box ml="10px">hours</Box>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <CustomInput value={displayDays(props.title==="Withdraw"?period:minutes * 60).minute} style={{width: '20px'}} onChange={onChangeMin} />
                            <Box ml="10px">minutes</Box>
                        </Box>
                        {props.title==="Withdraw"&&<Box display="flex" alignItems="center" ml="10px">
                            <CustomInput value={displayDays(period).second} style={{width: '20px'}} />
                            <Box ml="10px">seconds</Box>
                        </Box>}
                    </Box>
                    {props.title === "Deposit" &&<Box px="20px" mb="20px">
                        <PrettoSlider
                            min={min}
                            max={MaxLockTime}
                            valueLabelDisplay="auto"
                            aria-label="pretto slider"
                            value={minutes}
                            onChange={handleSlider}
                        />
                    </Box>}
                    <Box display="flex" width="50%">
                        <Paper color="#6EF47A" width="100%" height="100%" mr="1vw" hover={true}>
                            <Button
                                color="inherit"
                                fullWidth
                                variant="text"
                                onClick={depositOrWithdraw}
                                sx={{ borderRadius: '25px', height: '80%' }}
                                disabled={amount === '0'|| !amount || (period !== 0 && props.title==="Withdraw") }
                                // disabled={!checked || amount === 0 || balance === 0}
                            >{props.title}</Button>
                        </Paper>
                        <Paper color="#ED4529" width="100%" height="100%" hover={true}>
                            <Button color="inherit" fullWidth variant="text" onClick={handleClose} sx={{ borderRadius: '25px', height: '80%'}}>CANCEL</Button>
                        </Paper>
                    </Box>
                </Box>
            </Dialog>
        </div>
    );
}