import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import useAuth from '../hooks/useAuth';
import { depositPresale, getStakedAmountInPresale, getWAVAXAmount, getRemainAllocation } from '../hooks/useTokenInfo';
import { toFixed } from '../utils/calculation';
import Paper from './Paper';

interface DepositDialogProps {
    open: boolean;
    onClose: () => void;
    onEvent: ()=>void;
}

const CustomInput = styled((props: any) => <input {...props} />)`
    border: none;
    height: 1.8vw;
    font-size: 1vw;
    background: transparent;
    color: white;
    &:focus-visible{
        outline: none;
        border: none;
    }
`

export default function DepositPresaleDialog(props: DepositDialogProps) {
    const [open, setOpen] = React.useState(false);
    const { account } = useAuth()
    const [balance, setBalance] = React.useState(0);
    const [amount, setAmount] = React.useState("");
    const [depositBalance, setDepositBalance] = React.useState(0)
    const [remainAllocation, setRemainAllocation] = React.useState(0)


    React.useEffect(() => {
        setOpen(props.open)
        if(props.open) {
            setAmount("0")
            setBalance(0)
            if(!account) return;
            getWAVAXAmount(account).then(({balance}) => {
                setBalance(balance)
            })
            getStakedAmountInPresale(account).then(({_balance})=>{
                setDepositBalance(_balance)
            })
            getRemainAllocation().then((allocation: number)=>{
                setRemainAllocation(allocation/(10**18))
            })
        }
        // eslint-disable-next-line
    }, [props.open])

    const handleClose = () => {
        props.onClose();
        setOpen(false);
    };

    const deposit = async () => {
        if (!account) return;
        const val = parseFloat(amount)
        if(!val) {
            alert("Invalid amount")
            return;
        }
        await depositPresale(val, account)
        props.onEvent();
        handleClose();
    }

    const changeAmount = (e: any) => {
        if(15-depositBalance < Number(e.target.value) || balance < Number(e.target.value)){
            console.log(15-depositBalance);
            setAmount(Math.min(Math.min((15-depositBalance),remainAllocation), balance).toString())
        }

        else
            setAmount(e.target.value)
    }

    const setAmountPercentage = (percentage: number) => () => {
        setAmount(""+Math.min(Math.min((15-depositBalance),remainAllocation) * percentage / 100, balance));
        
            
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                      background: 'url(/image/bg3.png)',
                      backgroundSize: '100% 100%',
                      backgroundRepeat: 'no-repeat',
                      color: 'white'
                    },
                }}
            >
                <Box fontSize="2vw" fontWeight="500" mt="2vw" px="2vw" pt="1vw" style={{textTransform: 'uppercase'}}>
                    DEPOSIT
                </Box>
                <Box display="flex" flexDirection="column" px="2vw" pt="2vw" pb="4vw">
                    <Box display="flex" mt="1vw" alignItems="flex-start" flexDirection="column" width="100%">
                        <Box flex={1} display="flex" flexDirection="column" mb="3vw" width="100%">
                            <Box fontSize="12px" mb="1vw" color="#C9C9C9">Amount</Box>
                            <Box borderBottom=" 1px solid white" display="flex" justifyContent="space-between">
                                <CustomInput value={amount} onChange={changeAmount} />
                                <Box color="white" display="flex" mb="0.5vw" >
                                    <Paper p="3px" mr="0.4vw" hover={true}><Button color="inherit" onClick={setAmountPercentage(25)} variant="text" sx={{ borderRadius: '5px', textTransform: 'none', fontSize: '0.6vw', p: '0.3vw', minWidth: '1vw' }}>25%</Button></Paper>
                                    <Paper p="3px" mr="0.4vw" hover={true}><Button color="inherit" onClick={setAmountPercentage(50)} variant="text" sx={{ borderRadius: '5px', textTransform: 'none', fontSize: '0.6vw', p: '0.3vw', minWidth: '1vw' }}>50%</Button></Paper>
                                    <Paper p="3px" mr="0.4vw" hover={true}><Button color="inherit" onClick={setAmountPercentage(75)} variant="text" sx={{ borderRadius: '5px', textTransform: 'none', fontSize: '0.6vw', p: '0.3vw', minWidth: '1vw' }}>75%</Button></Paper>
                                    <Paper p="3px" hover={true}><Button color="inherit" onClick={setAmountPercentage(100)} variant="text" sx={{ borderRadius: '5px', textTransform: 'none', fontSize: '0.6vw', p: '0.3vw', minWidth: '1vw' }}>MAX</Button></Paper>
                                </Box>
                            </Box>
                            <Box fontSize="12px" mt="1vw" textAlign="right" color="#C9C9C9">Available&nbsp; 
                                <span style={{ color: '#ED4529' }}>{Number(balance) ? toFixed(Number(balance)): 0}</span>
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" width="50%">
                        <Paper color="#6EF47A" width="100%" height="100%" mr="1vw" hover={true}>
                            <Button
                                color="inherit"
                                fullWidth
                                variant="text"
                                onClick={deposit}
                                sx={{ borderRadius: '25px', height: '80%' }}
                                disabled={amount === '0' || !amount }
                            >DEPOSIT</Button>
                        </Paper>
                        <Paper color="#ED4529" width="100%" height="100%" hover={true}>
                            <Button color="inherit" fullWidth variant="text" onClick={handleClose} sx={{ borderRadius: '25px', height: '80%'}}>CANCEL</Button>
                        </Paper>
                    </Box>
                </Box>
            </Dialog>
        </div>
    );
}