import { Box } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import Topbar from "../Components/Topbar";
import DepositPresaleDialog from "../Components/DepositPresaleDialog";
import {
    getStakedAmountInPresale,
    getPendingLEOInPresale,
    getLPPriceInPresale,
    claimLEO,
    getTotalContributed,
    getLPPrice,
    checkWhiteList,
    checkClaimable,
    getContract,
    ABI
} from "../hooks/useTokenInfo";
import useAuth from "../hooks/useAuth";
import { toFixed } from "../utils/calculation";
import Paper from "../Components/Paper";
import Sidebar from "../Components/Sidebar";
import CountUp from 'react-countup';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import coin1 from "../assets/images/coin1.png"
import logo from "../assets/images/logo-large.png"
import useMobile from "../hooks/useMobile";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="standard" {...props} />;
});

const Presale: FC = () => {
    const [openDlg, setOpenDlg] = useState(false)
    const [balance, setBalance] = useState({ oldVal: 0, newVal: 0 });
    const [claimableVal, setClaimableVal] = useState({ oldVal: 0, newVal: 0 })
    const { account } = useAuth()
    const [lpPrice, setLpPrice] = useState({ oldVal: 0, newVal: 0 })
    const [LeoPrice, setLeoPrice] = useState({ oldVal: 0, newVal: 0 })
    const [totalContributed, setContributed] = useState({ oldVal: 0, newVal: 0 })
    const [openSnack, setOpenSnack] = React.useState({ open: false, title: "", isWhiteList: false });
    const [claimable, setClaimable] = useState(false)
    const [remainingLeo, setRemainingLeo] = useState({ oldVal: 0, newVal: 0 })
    const {isMobile} = useMobile()
    useEffect(() => {
 
            setBalance({ oldVal: 0, newVal: 0 })
            getTotalContributed().then((val: number) => {
                setContributed({ oldVal: totalContributed.newVal, newVal: val });
                setRemainingLeo({ oldVal: remainingLeo.newVal, newVal: 30000 - val / (10 ** 17) });
            })
            getLPPrice(0).then(({ _LeoPrice }) => {
                setLeoPrice({ oldVal: LeoPrice.newVal, newVal: _LeoPrice })
            });
            getLPPriceInPresale().then(({ LPprice }) => {
                setLpPrice({ oldVal: lpPrice.newVal, newVal: LPprice })
            });
            if (!account) {
                setOpenSnack({ open: false, title: "", isWhiteList: false })
                return;
            }
            getStakedAmountInPresale(account).then(({_balance}) => {
                setBalance({ oldVal: balance.newVal, newVal: _balance })
            })
            getPendingLEOInPresale(account).then((reward: number) => {
                setClaimableVal({ oldVal: claimableVal.newVal, newVal: reward / 100000 });
            })
            checkClaimable().then((res: boolean) => {
                setClaimable(res)
            })

            checkWhiteList(account).then((whiteList: boolean) => {
                if (whiteList) setOpenSnack({ open: true, title: 'Congratulations, your address is whitelisted', isWhiteList: true })
                else setOpenSnack({ open: true, title: 'This address is not whitelisted, please proceed to our regular presale', isWhiteList: false })
            }) 
// eslint-disable-next-line
    }, [account])
    React.useEffect(() => {
        
        const time = setInterval(() => {
            setBalance({ oldVal: 0, newVal: 0 })
            getTotalContributed().then((val: number) => {
                setContributed({ oldVal: totalContributed.newVal, newVal: val });
                setRemainingLeo({ oldVal: remainingLeo.newVal, newVal: 30000 - val / (10 ** 17) });
            })
            getLPPrice(0).then(({ _LeoPrice }) => {
                setLeoPrice({ oldVal: LeoPrice.newVal, newVal: _LeoPrice })
            });
            getLPPriceInPresale().then(({ LPprice }) => {
                setLpPrice({ oldVal: lpPrice.newVal, newVal: LPprice })
            });
            if (!account) {
                setOpenSnack({ open: false, title: "", isWhiteList: false })
                return;
            }
            getStakedAmountInPresale(account).then(({_balance}) => {
                setBalance({ oldVal: balance.newVal, newVal: _balance})
            })
            getPendingLEOInPresale(account).then((reward: number) => {
                setClaimableVal({ oldVal: claimableVal.newVal, newVal: reward / 100000 });
            })
            checkClaimable().then((res: boolean) => {
                setClaimable(res)
            })
            checkWhiteList(account).then((whiteList: boolean) => {
                console.log(whiteList);
                if (whiteList) setOpenSnack({ open: true, title: 'Congratulations, your address is whitelisted', isWhiteList: true })
                else setOpenSnack({ open: true, title: 'This address is not whitelisted, please proceed to our regular presale', isWhiteList: false })
            }) 
        }, 15000);
        return () => clearInterval(time);
// eslint-disable-next-line
    }, [account])

    const onEvnet = async() => {
        if(!account) return;
        const contract = await getContract(ABI.PresaleABI);
        contract.events.allEvents({
            filter: {user: account}, // Using an array means OR: e.g. 20 or 23
            // fromBlock: 0
        })
        .on('data', function(event:any){
            getTotalContributed().then((val: number) => {
                setContributed({ oldVal: totalContributed.newVal, newVal: val });
                setRemainingLeo({ oldVal: remainingLeo.newVal, newVal: 30000 - val / (10 ** 17) });
            })
            getLPPrice(0).then(({ _LeoPrice }) => {
                setLeoPrice({ oldVal: LeoPrice.newVal, newVal: _LeoPrice })
            });
            getLPPriceInPresale().then(({ LPprice }) => {
                setLpPrice({ oldVal: lpPrice.newVal, newVal: LPprice })
            });
            if (!account) {
                setOpenSnack({ open: false, title: "", isWhiteList: false })
                return;
            }
            getStakedAmountInPresale(account).then((val: any) => {
                setBalance({ oldVal: balance.newVal, newVal: val.balance })
            })
            getPendingLEOInPresale(account).then((reward: number) => {
                setClaimableVal({ oldVal: claimableVal.newVal, newVal: reward / 100000 });
            })
            checkClaimable().then((res: boolean) => {
                setClaimable(res)
            })
        })
        .on('changed', function(event:any){
        })
        .on('error', console.error);
    }


    return <Box
        display="flex"
        flexDirection="column"
        width="100%"
        style={{ background: 'url(/image/bg4.png)', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat' }}
        zIndex="0"
    >
        <Topbar mode="staking" />
        <Box display="flex" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
            <Sidebar />
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="center"
                width="75%"
                ml={isMobile ? "auto":"300px"}
                mr="auto"
                zIndex="5"
            >
                <Box fontSize="16px" p="5px" borderRadius="5px" border="1px red solid" color="red">CLOSED, PLEASE PROCEED TO PUBLIC SALE!</Box>

                
                <Box display="flex" justifyContent={"center"} alignItems="center" my="30px" sx={{flexDirection:{md: 'row', sm: 'column', xs: 'column' }}}>
                    <Paper p="15px" sx={{mr:{md: '20px', sm: '0', xs: '0' }, mb:{md: '0', sm: '30px', xs: '30px'}, width: {md:'auto', sm: '300px', xs: '300px'}}}>
                        <Box fontSize="16px" lineHeight="22px">Total LEO for Sale</Box>
                        <Box fontSize="24px" fontWeight={"500"} color="#6EF47A">30000</Box>
                        <Box fontSize="16px" lineHeight="26px" fontWeight="500">USD
                            <span style={{ color: '#6EF47A' }}>$
                                <CountUp start={lpPrice.oldVal * 3000 } decimal="." decimals={2} end={lpPrice.newVal * 3000}
                                    useEasing={true} duration={1.2} />
                            </span>
                        </Box>
                    </Paper>
                    <Paper p="15px" sx={{mr:{md: '20px', sm: '0', xs: '0' }, mb:{md: '0', sm: '30px', xs: '30px'}, width: {md:'auto', sm: '300px', xs: '300px'}}}>
                        <Box fontSize="16px" lineHeight="22px">Available LEO for Sale</Box>
                        <Box fontSize="24px" fontWeight={"500"} color="#6EF47A">
                            <CountUp start={remainingLeo.oldVal} decimal="." decimals={2} end={remainingLeo.newVal}
                                    useEasing={true} duration={1.2} />
                        </Box>
                        <Box fontSize="16px" lineHeight="26px" fontWeight="500">USD
                            <span style={{ color: '#6EF47A' }}>$
                                <CountUp start={remainingLeo.oldVal* 0.1*lpPrice.oldVal} decimal="." decimals={2} end={remainingLeo.newVal* 0.1*lpPrice.newVal}
                                    useEasing={true} duration={1.2} />
                            </span>
                        </Box>
                    </Paper>
                    <Paper p="15px" sx={{mr:{md: '20px', sm: '0', xs: '0' }, mb:{md: '0', sm: '30px', xs: '30px'}, width: {md:'auto', sm: '300px', xs: '300px'}}}>
                        <Box fontSize="16px" lineHeight="22px">Price per LEO</Box>
                        <Box fontSize="24px" fontWeight={"500"} color="#6EF47A">0.1 AVAX</Box>
                        <Box fontSize="16px" lineHeight="26px" fontWeight="500">USD
                            <span style={{ color: '#6EF47A' }}>$
                            <CountUp start={lpPrice.oldVal* 0.1} decimal="." decimals={2} end={lpPrice.newVal* 0.1}
                                    useEasing={true} duration={1.2} />
                            </span>
                        </Box>
                    </Paper>
                    <Paper p="15px" sx={{mr:{md: '20px', sm: '0', xs: '0' }, mb:{md: '0', sm: '30px', xs: '30px'}, width: {md:'auto', sm: '300px', xs: '300px'}}}>
                        <Box fontSize="16px" lineHeight="22px">Total contributed</Box>
                        <Box fontSize="24px" fontWeight={"500"} color="#6EF47A">
                        <CountUp start={totalContributed.oldVal / (10 ** 18)} decimal="." decimals={2} end={totalContributed.newVal / (10 ** 18)}
                                    useEasing={true} duration={1.2} /> AVAX
                        </Box>
                        <Box fontSize="16px" lineHeight="26px" fontWeight="500">USD
                            <span style={{ color: '#6EF47A' }}>$
                            <CountUp start={lpPrice.oldVal * (totalContributed.oldVal / (10 ** 18))} decimal="." decimals={2} end={lpPrice.newVal * (totalContributed.newVal / (10 ** 18))}
                                    useEasing={true} duration={1.2} />
                            </span>
                        </Box>
                    </Paper>
                </Box>
                <Box fontSize="15px" lineHeight="10px" justifyContent="center" alignItems="center" color="yellow">MAX ALLOCATION: 15 WAVAX PER WALLET</Box>
                <Box display="flex" mb="30px" mt ="25px"  justifyContent={"center"} alignItems="center" flexDirection="column">
                    <Paper p="20px" mb="20px" flex={1} width="100%" maxWidth="230px">
                        <Box fontSize="14px" lineHeight="26px">WAVAX contributed</Box>
                        <Paper p="7px" display="flex"  left="50%" top="-17px" style={{transform: 'translateX(-50%)'}} position="absolute"  alignItems="center" justifyContent="center" bgcolor="lightgray">
                            <Box component="img" src={coin1} width="20px" />
                        </Paper>
                        <Box fontSize="32px" fontWeight={"600"} lineHeight="50px" color="#6EF47A" >{Number(balance.newVal)? toFixed(Number(balance.newVal)) : 0}</Box>
                        <Box fontSize="16px" lineHeight="26px" fontWeight="500" mb="30px">USD
                            <span style={{ color: '#6EF47A' }}>$
                            <CountUp start={lpPrice.oldVal * balance.oldVal} decimal="." decimals={2} end={lpPrice.newVal * balance.newVal}
                                    useEasing={true} duration={1.2} />
                            </span>
                        </Box>

                    </Paper>
                    <Paper p="20px" mt= "10px" flex={1} width="100%" mb="20px" maxWidth="230px" >
                        <Box fontSize="14px" lineHeight="26px">LEO allocation</Box>
                        <Paper p="7px" display="flex"  left="50%" top="-17px" style={{transform: 'translateX(-50%)'}} position="absolute"  alignItems="center" justifyContent="center" bgcolor="lightgray">
                            <Box component="img" src={logo} width="20px" />
                        </Paper>
                        <Box fontSize="32px" fontWeight={"600"} lineHeight="50px" color="#6EF47A" >
                            <CountUp start={claimableVal.oldVal} decimal="." decimals={2} end={claimableVal.newVal}
                                    useEasing={true} duration={1.2} />
                        </Box>

                        <Box fontSize="16px" lineHeight="26px" fontWeight="500" mb="30px">USD
                            <span style={{ color: '#6EF47A' }}>$
                            <CountUp start={claimableVal.oldVal * LeoPrice.oldVal} decimal="." decimals={2} end={claimableVal.newVal * LeoPrice.newVal}
                                    useEasing={true} duration={1.2} />
                            </span>
                        </Box>
                        <Paper
                            width="100%"
                            color="#FCF686"
                            fontSize="24px"
                            disabled={!openSnack.isWhiteList || !claimable}
                            style={!openSnack.isWhiteList || !claimable ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
                            onClick={
                                async () => {
                                    if (!openSnack.isWhiteList || !claimable) return;
                                    await claimLEO(account || "")
                                    onEvnet();
                                }
                            }
                            hover={true}>CLAIM</Paper>
                    </Paper>
                    {openSnack.open && <Alert severity={openSnack.isWhiteList ? "success" : "warning"} sx={{ width: '100%', mb: '100px' }}>
                        {openSnack.title}
                    </Alert>}
                </Box>
            </Box>
        </Box>

        <DepositPresaleDialog
            open={openDlg}
            onClose={() => setOpenDlg(false)}
            onEvent={onEvnet}
        />
    </Box>
}
export default Presale;