/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty-pattern */
import Web3 from "web3";
import ERC20ABI from "../contracts/ERC20ABI.json";
import ContractABI from "../contracts/Contract.json";
import LPtokenABI from "../contracts/LPtokenABI.json"
import PresaleABI from "../contracts/PresaleABI.json"
import xLEOABI from "../contracts/xLEOABI.json"
import { getTokenPrice, WAVAX_ID } from "./getDataFromApi";

declare let window: any;
const Hour = 3600;
const Day = 24 * Hour;
const Year = 365 * Day;

export enum ABI { StakingABI, LPtokenABI, PresaleABI, XLeoABI }

const getPoolTokenAddr = async (_pid: number) => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const contract = await new window.web3.eth.Contract(ContractABI, process.env.REACT_APP_CONTRACT_ADDR);
    const poolInfo = await contract.methods.poolInfo(_pid).call()
    return poolInfo.lpToken;
}

export const getContract = async (abi: number) => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    switch (abi) {
        case ABI.StakingABI:
            return await new window.web3.eth.Contract(ContractABI, process.env.REACT_APP_CONTRACT_ADDR);
        case ABI.LPtokenABI:
            return await new window.web3.eth.Contract(LPtokenABI, process.env.REACT_APP_LEO_CONTRACT_ADDR);
        case ABI.PresaleABI:
            return await new window.web3.eth.Contract(PresaleABI, process.env.REACT_APP_PRESALE_CONTRACT_ADDR);
        case ABI.XLeoABI:
            return await new window.web3.eth.Contract(xLEOABI, process.env.REACT_APP_XLEO_CONTRACT_ADDR);
        default: return;
    }
}

export const getUserBalance = async (_pid: number, account: string) => {

    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const tokencontract = await new window.web3.eth.Contract(ERC20ABI, await getPoolTokenAddr(_pid));
    const contract = await new window.web3.eth.Contract(ContractABI, process.env.REACT_APP_CONTRACT_ADDR);
    const userInfo = await contract.methods.userInfo(_pid, account).call()
    const Decimal = await tokencontract.methods.decimals().call();
    const balance = await tokencontract.methods.balanceOf(account).call();
    return { balance: balance / Math.pow(10, Decimal), lockTime: userInfo.unlockTime };
}

export const getUserRemainLockTime = async (_pid: number, account: string) => {

    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const contract = await new window.web3.eth.Contract(ContractABI, process.env.REACT_APP_CONTRACT_ADDR);
    const remaining = await contract.methods.getRemainingLockedTime(_pid, account).call()
    return remaining;
}

export const approve = async (_pid: number, account?: string) => {
    if (!account) return;
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const tokencontract = await new window.web3.eth.Contract(ERC20ABI, await getPoolTokenAddr(_pid));
    return await tokencontract.methods.approve(process.env.REACT_APP_CONTRACT_ADDR, '0x99999999999999999999999999999').send({ from: account });
}

export const getAllowance = async (_pid: number, account: string) => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }

    const tokencontract = await new window.web3.eth.Contract(ERC20ABI, await getPoolTokenAddr(_pid));
    return await tokencontract.methods.allowance(account, process.env.REACT_APP_CONTRACT_ADDR).call();
}

export const getPendingLEO = async (_pid: number, account: string) => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const contract = await new window.web3.eth.Contract(ContractABI, process.env.REACT_APP_CONTRACT_ADDR);
    return await contract.methods.pendingLEO(_pid, account).call();
}

export const deposit = async (_pid: number, amount: number, lockTime: number, account: string) => {

    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }

    const tokencontract = await new window.web3.eth.Contract(ERC20ABI, await getPoolTokenAddr(_pid));
    const Decimal = await tokencontract.methods.decimals().call();
    // await tokencontract.methods.approve(process.env.REACT_APP_CONTRACT_ADDR, '0x' + (Math.round(amount * Math.pow(10, Decimal))).toString(16)).send({ from: account });
    const contract = await new window.web3.eth.Contract(ContractABI, process.env.REACT_APP_CONTRACT_ADDR);
    contract.methods.deposit(_pid, '0x' + (Math.round(amount * Math.pow(10, Decimal))).toString(16), lockTime).send({ from: account });
}

export const getStakedAmountAndLockedPeriod = async (_pid: number, account: string) => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const contract = await new window.web3.eth.Contract(ContractABI, process.env.REACT_APP_CONTRACT_ADDR);
    const userInfo = await contract.methods.userInfo(_pid, account).call()
    const tokencontract = await new window.web3.eth.Contract(ERC20ABI, await getPoolTokenAddr(_pid));
    const Decimal = await tokencontract.methods.decimals().call();

    return { _balance: userInfo.amount / Math.pow(10, Decimal), lockTime: userInfo.unlockTime, _ratio: userInfo.amount !== 0 ? userInfo.userPoint / userInfo.amount : 1 };
}

export const withdraw = async (_pid: number, amount: number, account: string) => {

    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const tokencontract = await new window.web3.eth.Contract(ERC20ABI, await getPoolTokenAddr(_pid));
    const Decimal = await tokencontract.methods.decimals().call();
    const contract = await new window.web3.eth.Contract(ContractABI, process.env.REACT_APP_CONTRACT_ADDR);
    contract.methods.withdraw(_pid, '0x' + (Math.round(amount * Math.pow(10, Decimal))).toString(16)).send({ from: account });
    return contract;
}

export const getLPPrice = async (_pid: number) => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const AvaxPrice = await getTokenPrice(WAVAX_ID);
    const tokencontract = await new window.web3.eth.Contract(LPtokenABI, await getPoolTokenAddr(_pid));
    const totalSupply = await tokencontract.methods.totalSupply().call();
    const totalVal = await tokencontract.methods.balanceOf(process.env.REACT_APP_CONTRACT_ADDR).call();

    const reserveList = await tokencontract.methods.getReserves().call();
    const reserve1 = reserveList[1];
    const LeoPrice = (reserve1 / Math.pow(10, 18) * AvaxPrice) / reserveList[0] * Math.pow(10, 5);

    const contract = await new window.web3.eth.Contract(ContractABI, process.env.REACT_APP_CONTRACT_ADDR);
    const totPoint = await contract.methods.poolInfo(_pid).call();

    const totalP = totPoint.totalPoint;

    const LEOContract = await new window.web3.eth.Contract(ERC20ABI, await contract.methods.LEO().call());
    const LEOstaked = await LEOContract.methods.balanceOf(process.env.REACT_APP_CONTRACT_ADDR).call();
    const LEODecimal = await LEOContract.methods.decimals().call();
    console.log(LEODecimal);

    const LEOPerSecond = await contract.methods.LEOPerSecond().call() / Math.pow(10, LEODecimal)
    console.log(LEOPerSecond);
    // const Token1Price = await getTokenPrice(SAFUU_ID);

    //wftm * reserve0 * 2/ totalSupply of lp tokens = lp_token_price
    const LPprice = (2 * reserve1 * AvaxPrice) / (totalSupply);
    // console.log(totalVal,LPprice);
    const TVL = totalVal * LPprice / Math.pow(10, 18);
    //console.log(TVL);
    const inflatedTVL = TVL * totalP / totalVal;
    console.log(inflatedTVL);
    const APR = TVL ? LEOPerSecond * Year * LeoPrice / inflatedTVL * 100 : 0;
    //console.log(APR);
    //tvl = total lp tokens staked in contract* lp token price
    return { _LPprice: LPprice / 1e18, _TVL: TVL, _APR: APR, _LeoPrice: LeoPrice,_LEOstaked: LEOstaked/100000 }
}

export const getTotalContributed = async () => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const presaleContract = await new window.web3.eth.Contract(PresaleABI, process.env.REACT_APP_PRESALE_CONTRACT_ADDR)
    const totalContributed = await presaleContract.methods.totalContributed().call();
    return totalContributed;
}

export const claimLEO = async (account: string) => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const presaleContract = await new window.web3.eth.Contract(PresaleABI, process.env.REACT_APP_PRESALE_CONTRACT_ADDR)
    await presaleContract.methods.claimLEO().send({ from: account });
}

export const getWAVAXAmount = async (account: string) => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const WAVAX = await new window.web3.eth.Contract(ERC20ABI, "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7");
    const Decimal = await WAVAX.methods.decimals().call();
    const balance = await WAVAX.methods.balanceOf(account).call()
    return { balance: balance / Math.pow(10, Decimal) };
}

export const getStakedAmountInPresale = async (account: string) => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const presaleContract = await new window.web3.eth.Contract(PresaleABI, process.env.REACT_APP_PRESALE_CONTRACT_ADDR)
    const userInfo = await presaleContract.methods.userInfo(account).call()
    const tokencontract = await new window.web3.eth.Contract(ERC20ABI, "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7");
    const Decimal = await tokencontract.methods.decimals().call();
    return { _balance: userInfo.amount / Math.pow(10, Decimal) };
}

export const depositPresale = async (amount: number, account: string) => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const presaleContract = await new window.web3.eth.Contract(PresaleABI, process.env.REACT_APP_PRESALE_CONTRACT_ADDR)
    const tokencontract = await new window.web3.eth.Contract(ERC20ABI, "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7");
    const Decimal = await tokencontract.methods.decimals().call();
    await presaleContract.methods.deposit('0x' + (Math.round(amount * Math.pow(10, Decimal))).toString(16)).send({ from: account });
}

export const getAllowanceInPresale = async (account: string) => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }

    const WAVAXContract = await new window.web3.eth.Contract(ERC20ABI, "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7");
    return await WAVAXContract.methods.allowance(account, process.env.REACT_APP_PRESALE_CONTRACT_ADDR).call();
}

export const getPendingLEOInPresale = async (account: string) => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const presaleContract = await new window.web3.eth.Contract(PresaleABI, process.env.REACT_APP_PRESALE_CONTRACT_ADDR)
    return await presaleContract.methods.getPendingLEO(account).call();
}

export const approveInPresale = async (account?: string) => {
    if (!account) return;
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const tokencontract = await new window.web3.eth.Contract(ERC20ABI, "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7");
    return await tokencontract.methods.approve(process.env.REACT_APP_PRESALE_CONTRACT_ADDR, '0x99999999999999999999999999999').send({ from: account });
}

export const getLPPriceInPresale = async () => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const AvaxPrice = await getTokenPrice(WAVAX_ID);
    console.log("****************8", AvaxPrice)
    return { LPprice: AvaxPrice }
}

export const checkWhiteList = async (address: string) => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const presaleContract = await new window.web3.eth.Contract(PresaleABI, process.env.REACT_APP_PRESALE_CONTRACT_ADDR)
    return await presaleContract.methods.Whitelist(address).call()
}

export const checkClaimable = async () => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const presaleContract = await new window.web3.eth.Contract(PresaleABI, process.env.REACT_APP_PRESALE_CONTRACT_ADDR)
    return await presaleContract.methods.claimable().call()
}

export const getRemainAllocation = async () => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const presaleContract = await new window.web3.eth.Contract(PresaleABI, process.env.REACT_APP_PRESALE_CONTRACT_ADDR)
    return await presaleContract.methods.remainAllocation().call()
}