import { Box, styled } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import Topbar from "../Components/Topbar";
import WrapDialog from "../Components/WrapDialog";
import { getLPPrice, getContract, ABI } from "../hooks/useTokenInfo";
import { xLEOForLEO, LEOBalance, getUserxLEOBalance, getTotalStaked, getTotalBurned} from "../hooks/xLEOinfo";
import useAuth from "../hooks/useAuth";
import Paper from "../Components/Paper";
import Sidebar from "../Components/Sidebar";
import CountUp from 'react-countup';
import useMobile from "../hooks/useMobile";
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Bottombar from "../Components/Bottombar";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="standard" {...props} />;
});

const CustomBtn = styled((props: any) => <button {...props} />)`
    background: ${(props) => (props.disabled ? "#797293" : "linear-gradient(45deg, #1e073f 10%, #a634fb 100%)")};
    border: ${(props) => (props.disabled ? "none" : "solid 1px #a634fb")};
    color: ${(props) => (props.disabled ? "#b29da6" : "white")};
    border-radius: 15px;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
`

const XLeoWrap: FC = () => {
    const [openDlg, setOpenDlg] = useState({ open: false, title: '' })
    const [balance, setBalance] = useState({oldVal: 0, newVal: 0});
    const [TVL, setTVL] = useState({oldVal: 0, newVal: 0});
    const [Burned, setBurned] = useState({oldVal: 0, newVal: 0});
    const [xBalance, setxBalance] = useState({oldVal: 0, newVal: 0});
    const { account } = useAuth()
    const [LeoPrice, setLeoPrice] = useState({oldVal: 0, newVal: 0})
    const [timer, setTimer] = React.useState<any>({})
    const [period, setPeriod] = React.useState<number>(-1)
    const [contract, setContract] = useState<any>({})
    const [ratio, setRatio] = useState({oldVal:0, newVal: 0})
    const {isMobile} = useMobile()

    useEffect(() => {
        setBalance({oldVal: 0, newVal: 0})
        getLPPrice(0).then(({ _LeoPrice }) => {
            setLeoPrice({oldVal: LeoPrice.newVal, newVal: _LeoPrice})
            
        });
        getTotalStaked().then(({_TVL}) =>{
            setTVL({oldVal:TVL.newVal,newVal:_TVL})
        });
        getTotalBurned().then(({_TVLburned}) =>{
            setBurned({oldVal:Burned.newVal,newVal:_TVLburned})
        });

        xLEOForLEO(100000).then((rate: number)=>{
            setRatio({oldVal:ratio.newVal, newVal:rate})
        })
        if (!account) return;
        LEOBalance(account).then(({_balance}) => {
            setBalance({oldVal: balance.newVal, newVal: _balance})
        })
        getUserxLEOBalance(0, account).then(({balance})=> {
            setxBalance({oldVal:xBalance.newVal, newVal: balance});
        })
        setPeriod(0);
        getContract(ABI.XLeoABI).then((contract: any)=>{setContract(contract)})
        // eslint-disable-next-line
    }, [account])

    React.useEffect(() => {
        clearTimeout(timer);
        const time = setTimeout(() => {
                getLPPrice(0).then(({_LeoPrice }) => {
                    setLeoPrice({oldVal: LeoPrice.newVal, newVal: _LeoPrice})
                });
                xLEOForLEO(100000).then((rate: number)=>{
                    setRatio({oldVal:ratio.newVal, newVal:rate})
                })
                getTotalStaked().then(({_TVL}) =>{
                    setTVL({oldVal:TVL.newVal,newVal:_TVL})
                });
                getTotalBurned().then(({_TVLburned}) =>{
                    setBurned({oldVal:Burned.newVal,newVal:_TVLburned})
                });
        
            setPeriod((period) => period + 1);
        }, 10000);
        setTimer(time)
        // eslint-disable-next-line
    }, [period]);

    const onEvnet = () => {
        if(!account) return;
        contract.events.allEvents({
            filter: {user: account, pid: 0}, // Using an array means OR: e.g. 20 or 23
        })
        .on('data', function(event:any){
            console.log("evnet trigger", event)
            xLEOForLEO(100000).then((rate: number)=>{
                setRatio({oldVal:ratio.newVal, newVal:rate})
            })
            LEOBalance(account).then(({_balance}) => {
                setBalance({oldVal: balance.newVal, newVal: _balance})
            })
        })
        .on('changed', function(event:any){
        })
        .on('error', console.error);
    }

    return <Box
        display="flex"
        flexDirection="column"
        width="100%"
        style={{ background: 'url(/image/bg4.png)', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat' }}
        zIndex="0"
    >
        <Topbar mode="staking" />
        <Box display="flex" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
            <Sidebar />
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="center"
                width="75%"
                m="auto"
                ml={isMobile ? "auto":"300px"}
                mb="auto"
                zIndex="5"
            >
                <Box display="flex" justifyContent={"center"} alignItems="center" mt="30px" mb="30px" sx={{flexDirection:{md: 'row', sm: 'column', xs: 'column' }}}>
                    <Paper p="15px" maxWidth="300px" width="300px">
                        <Box fontSize="16px" lineHeight="22px">xLEO vs LEO</Box>
                        <Box fontSize="24px" fontWeight={"500"} color="#6EF47A">1 xLEO =&nbsp;
                        <CountUp start={ratio.oldVal/100000} decimal="." decimals={4} end={ratio.newVal/100000 }
                                useEasing={true} duration={1.2} />&nbsp;LEO
                        </Box>
                    </Paper>
                    <Paper p="15px" mr ="15px" ml ="15px" maxWidth="300px" width="300px">
                        <Box fontSize="16px" lineHeight="22px">Rebase APY</Box>
                        <Box fontSize="24px" fontWeight={"500"} color="#6EF47A">
                        
                        <CountUp start={100000} decimal="." decimals={2} end={382092 }
                                useEasing={true} duration={1.2} />&nbsp;%
                        </Box>
                    </Paper>
                    <Paper p="15px" maxWidth="300px" width="300px">
                        <Box fontSize="16px" lineHeight="22px">Your xLEO balance</Box>
                        <Box fontSize="24px" fontWeight={"500"} color="#6EF47A">
                        
                        <CountUp start={xBalance.oldVal} decimal="." decimals={2} end={xBalance.newVal}
                                useEasing={true} duration={1.2} />&nbsp;xLEO
                        </Box>
                    </Paper>
                </Box>
                <Box display="flex" justifyContent={"center"} alignItems="center" sx={{flexDirection:{md: 'row', sm: 'column', xs: 'column' }}}>
                    <Paper p="20px" maxWidth="300px" width="200px" mr ="5px" flex={1} position="relative">
                        <Box fontSize="14px" lineHeight="26px">Total xLEO Burned</Box>
                        <Box fontSize="25px" fontWeight={"600"} lineHeight="50px" color="#FCF686" >
                            <CountUp start={Burned.oldVal/ (10**5)} decimal="." decimals={2} end={Burned.newVal/ (10**5)}
                                    useEasing={true} duration={1.2} /> xLEO
                        </Box> 
                        <Box fontSize="16px" lineHeight="26px" fontWeight="500">USD 
                            <span style={{ color: '#FCF686' }}>$
                                <CountUp start={LeoPrice.oldVal * Burned.oldVal*ratio.oldVal / (10 ** 10)} decimal="." decimals={2} end={LeoPrice.newVal * Burned.newVal*ratio.newVal/( 10 ** 10)}
                                    useEasing={true} duration={1.2} />
                            </span>
                        </Box>
     
                    </Paper>
                    <Paper p="20px" maxWidth="300px" width="200px" ml ="5px" flex={1} position="relative">
                        <Box fontSize="14px" lineHeight="26px">Total LEO staked</Box>
                        <Box fontSize="25px" fontWeight={"600"} lineHeight="50px" color="#FCF686" >
                            <CountUp start={TVL.oldVal/ (10**5)} decimal="." decimals={2} end={TVL.newVal/ (10**5)}
                                    useEasing={true} duration={1.2} /> LEO
                        </Box> 
                        <Box fontSize="16px" lineHeight="26px" fontWeight="500">USD 
                            <span style={{ color: '#FCF686' }}>$
                                <CountUp start={LeoPrice.oldVal * TVL.oldVal / (10 ** 5)} decimal="." decimals={2} end={LeoPrice.newVal * TVL.newVal/( 10 ** 5)}
                                    useEasing={true} duration={1.2} />
                            </span>
                        </Box>
     
                    </Paper>
                </Box>
                <Box display="flex" mb="30px" mt="30px" justifyContent={"center"} alignItems="center" width="60%" sx={{flexDirection:{md: 'row', sm: 'column-reverse', xs: 'column-reverse' }}}>

                    <Paper p="20px" maxWidth="300px" width="300px" flex={1} position="relative">
                        <Box fontSize="14px" lineHeight="26px">Withdrawable LEO</Box>
                        <Box fontSize="32px" fontWeight={"600"} lineHeight="50px" color="#FCF686" >
                            <CountUp start={balance.oldVal/ (10**5)} decimal="." decimals={8} end={balance.newVal/ (10**5)}
                                    useEasing={true} duration={1.2} />
                        </Box>
                        <Box fontSize="16px" lineHeight="26px" fontWeight="500" mb="30px">USD 
                            <span style={{ color: '#FCF686' }}>$
                                <CountUp start={LeoPrice.oldVal * balance.oldVal / (10 ** 5)} decimal="." decimals={2} end={LeoPrice.newVal * balance.newVal/( 10 ** 5)}
                                    useEasing={true} duration={1.2} />
                            </span>
                        </Box>
                        <Box display="flex" justifyContent="space-between" width="70%">
                            <Paper width="50px" onClick={() => { setOpenDlg({ open: true, title: 'Unwrap' }) }} style={{ cursor: 'pointer' }} hover={true}>-</Paper>
                            <CustomBtn style={{ width: "40px", display: 'flex', justifyContent: 'center', alignItems: 'center' }} disabled>
                                <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{ color: "rgb(189, 189, 189)" }}><path d="M7 2v11h3v9l7-12h-4l4-8z"></path></svg>
                            </CustomBtn>
                            <Paper width="50px" onClick={() => { setOpenDlg({ open: true, title: 'Wrap' }) }} style={{ cursor: 'pointer' }} hover={true}>+</Paper>
                        </Box>
                    </Paper>
                </Box>
                <Box display="flex" justifyContent={"center"} alignItems="center" flexDirection="column">
                    <Alert severity= "success" sx={{ width: '100%', mb: '15px' }}>
                        <Box display="flex" justifyContent={"center"} alignItems="center">
                            xLEO appreciates in value against LEO and xLEO holders earn rebase APR + buybacked LEOs
                        </Box>
                    </Alert>
                </Box>
                <Box display="flex" justifyContent={"center"} alignItems="center" flexDirection="column">
                    <Alert severity= "success" sx={{ width: '100%', mb: '50px' }}>
                        <Box display="flex" justifyContent={"center"} alignItems="center">
                            xLEO holders can also vote for future proposals of the protocol
                        </Box>
                    </Alert>
                </Box>
                <Bottombar />
            </Box>
        </Box>
        <WrapDialog
            open={openDlg.open}
            title={openDlg.title}
            onClose={() => { setOpenDlg({ open: false, title: '' }) }}
            onEvent={onEvnet}
        />
    </Box>
}
export default XLeoWrap;