import { Box } from "@mui/material";
import React from "react";
import rm from "../../assets/images/roadmap1.png"
import useMobile from "../../hooks/useMobile";
import Topbar from "../Topbar";
const Roadmap = () => {
     const { isMobile } = useMobile();
     return<Box
     display="flex"
     flexDirection="column"
     alignItems="center"
     overflow="auto"
     width="100%"
     style={{ background: 'url(/image/mainbg.png)', backgroundSize: '100% 100%' }}
 > 
    <Topbar mode="home" />
    <Box  component="img" width={isMobile? "400px":"1200px"} src={rm} />
 </Box>
}

export default Roadmap;