import { Box } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import Topbar from "../Components/Topbar";
import LEO_ABI from '../contracts/LEO_ABI.json'
import Web3 from "web3";
import { getValueOfTreasury, getValueOfInsuranceFund, getValueOfPoolVal, getTokenPrice, WAVAX_ID, ETH_ID } from "../hooks/getDataFromApi";
import { getLPPrice } from "../hooks/useTokenInfo";
import { getTotalBurned, getTotalStaked, getUserxLEOBalance, LEOBalance, xLEOForLEO } from "../hooks/xLEOinfo";
import Sidebar from "../Components/Sidebar"
import Paper from "../Components/Paper";
import CountUp from 'react-countup';
import useMobile from "../hooks/useMobile";
import useAuth from "../hooks/useAuth";
import { roundWithPrecision } from "../utils/calculation";
import { getLP } from "../hooks/incomeInfo";
import { getLP2 } from "../hooks/incomeInfoETH";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from '../app/hooks';
import {
    setToggle
} from '../actions/ToggleMode';

declare let window: any;

const DappDashboard: FC = () => {
    let navigate = useNavigate();

    const dispatch = useAppDispatch();

    const toggle = () => {
        if(!isMobile) return;
        dispatch(setToggle())
    }

    const onClickItem = (url: string) => () => {
        navigate(url);
        toggle();
    }

    const decimal = 5;
    const [LEOPrice, setLEOPrice] = useState({ oldVal: 0, newVal: 0 })
    const [totalSupply, setTotalSupply] = useState({ oldVal: 0, newVal: 0 })
    const [circulatingSupply, setcirculatingSupply] = useState({ oldVal: 0, newVal: 0 })
    const [valueOfFirePit, setValueOfFirePit] = useState({ oldVal: 0, newVal: 0 })
    const [treasury, setTreasury] = useState({ oldVal: 0, newVal: 0 })
    const [insuranceFund, setInsuranceFund] = useState({ oldVal: 0, newVal: 0 })
    const [LeoInFarm, setLeoInFarm] = useState({ oldVal: 0, newVal: 0 })
    const [poolVal, setPoolVal] = useState({ oldVal: 0, newVal: 0 })
    const [timer, setTimer] = useState<any>(0)
    const { isMobile } = useMobile()
    const { account } = useAuth()
    const [Burned, setBurned] = useState({ oldVal: 0, newVal: 0 });
    const [LeoBurned, setLeoBurned] = useState({ oldVal: 0, newVal: 0 });
    const [ratio, setRatio] = useState({ oldVal: 0, newVal: 0 })
    const [xBalance, setxBalance] = useState({ oldVal: 0, newVal: 0 });
    const [LeoPrice, setLeoPrice] = useState({ oldVal: 0, newVal: 0 })
    const [TVL, setTVL] = useState({ oldVal: 0, newVal: 0 });
    const [liquidityTVL, setLiquidityTVL] = useState({ oldVal: 0, newVal: 0 });
    const [AvaxPrice, setAvaxPrice] = useState({ oldVal: 0, newVal: 0 })
    const [ETHPrice, setETHPrice] = useState({ oldVal: 0, newVal: 0 })
    const [total, setTotal] = useState({ oldVal: 0, newVal: 0 })
    const [incomeTVL, setIncomeTVL] = useState({ oldVal: 0, newVal: 0 })
    const [total2, setTotal2] = useState({ oldVal: 0, newVal: 0 })
    const [incomeTVL2, setIncomeTVL2] = useState({ oldVal: 0, newVal: 0 })
    const [APR, setAPR] = useState({oldVal: 0, newVal: 0})
    const [liquidityRatio, setLiquidityRatio] = useState({ oldVal: 0, newVal: 0 })
    const [period, setPeriod] = React.useState<number>(-1)

    useEffect(() => {
        init(true)
        setPeriod(1);
        console.log("init")
        // eslint-disable-next-line
    }, [account])

    // eslint-disable-next-line
    useEffect(() => {
        clearInterval(timer);
        const time = setTimeout(() => {
            init(false)
            getTotalBurned().then(({ _TVLburned }) => {
                setBurned({ oldVal: Burned.newVal, newVal: _TVLburned })
            });
            LEOBalance("0x000000000000000000000000000000000000dEaD").then(({ _balance }) => {
                setLeoBurned({ oldVal: LeoBurned.newVal, newVal: _balance / 100000 })
            });
            setPeriod((period) => period + 1);
        }, 20000);        
        setTimer(time)
        // eslint-disable-next-line
    },[period]);

    const init = async (timerFlag: boolean) => {

        console.log("here");
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum);
        }

        const LEO_contract = new window.web3.eth.Contract(LEO_ABI as any, process.env.REACT_APP_LEO_CONTRACT_ADDR);
        if (timerFlag) {
            clearTimeout(timer);
        }
        const _totalSupply = await LEO_contract.methods.totalSupply().call();
        const _circulatingSupply = await LEO_contract.methods.getCirculatingSupply().call();
        const treasuryAddr = await LEO_contract.methods.treasuryReceiver().call();
        const insuranceFundAddr = await LEO_contract.methods.LeoInsuranceFundReceiver().call();
        const firePitAddr = await LEO_contract.methods.firePit().call();
        const pairAddr = await LEO_contract.methods.pairAddress().call();

        setLEOPrice({ oldVal: LEOPrice.newVal, newVal: (await getLPPrice(0))._LeoPrice });
        setLeoInFarm({ oldVal: LeoInFarm.oldVal, newVal: (await getLPPrice(0))._LEOstaked });
        setTotalSupply({ oldVal: totalSupply.newVal, newVal: parseFloat(_totalSupply) / (10 ** decimal) })

        setValueOfFirePit({ oldVal: valueOfFirePit.newVal, newVal: await LEO_contract.methods.balanceOf(firePitAddr).call() / (10 ** decimal) })
        setTreasury({ oldVal: treasury.newVal, newVal: await getValueOfTreasury(treasuryAddr) })
        setInsuranceFund({ oldVal: insuranceFund.newVal, newVal: await getValueOfInsuranceFund(insuranceFundAddr) })
        setPoolVal({ oldVal: poolVal.newVal, newVal: await getValueOfPoolVal(pairAddr) })

        console.log(LeoInFarm);
        setcirculatingSupply({ oldVal: circulatingSupply.newVal, newVal: (_circulatingSupply / (10 ** decimal)) })
        getTotalBurned().then(({ _TVLburned }) => {
            setBurned({ oldVal: Burned.newVal, newVal: _TVLburned })
        });
        getLPPrice(0).then(({ _LeoPrice, _TVL, _APR }) => {
            setLeoPrice({ oldVal: LeoPrice.newVal, newVal: _LeoPrice })
            setLiquidityTVL({ oldVal: TVL.newVal, newVal: _TVL })
            setAPR({oldVal: APR.newVal, newVal: _APR})
        });
        getTotalStaked().then(({ _TVL }) => {
            setTVL({ oldVal: TVL.newVal, newVal: _TVL })
        });

        xLEOForLEO(100000).then((rate: number) => {
            setRatio({ oldVal: ratio.newVal, newVal: rate })
        })
        if (!account) return;
        getUserxLEOBalance(0, account).then(({ balance }) => {
            setxBalance({ oldVal: xBalance.newVal, newVal: balance });
        })
        setAvaxPrice({ oldVal: AvaxPrice.newVal, newVal: await getTokenPrice(WAVAX_ID) });
        setETHPrice({ oldVal: ETHPrice.newVal, newVal: await getTokenPrice(ETH_ID) });
        getLP(0).then(({ _TVL, _total }) => {

            setIncomeTVL({ oldVal: TVL.newVal, newVal: _TVL })
            setTotal({ oldVal: total.newVal, newVal: _total })
        });
        getLP2(0).then(({ _TVL, _total }) => {

            setIncomeTVL2({ oldVal: TVL.newVal, newVal: _TVL })
            setTotal2({ oldVal: total.newVal, newVal: _total })
        });
        setLiquidityRatio({oldVal:2, newVal: 2});

    }

    return <Box
        display="flex"
        flexDirection="column"
        width="100%"
        style={{ background: 'url(/image/bg4.png)', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat' }}
        zIndex="0"
    >
        <Topbar mode="staking" />
        <Box display="flex" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
            <Sidebar />
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="center"
                width="75%"
                ml={isMobile ? "auto" : "300px"}
                mr="auto"
                zIndex="5"
            >
                <Paper display="flex" flexDirection="column" mt="20px" mb="15px" width="800px" p="15px" >
                    <Box mb="5px" fontSize="20px" style={{ textTransform: 'uppercase' }}>Total Value Locked</Box>
                    <Box fontSize="30px" color="#6EF47A">$
                        <CountUp
                            start={LeoPrice.oldVal * TVL.oldVal / (10 ** 5) + liquidityTVL.oldVal + incomeTVL.oldVal * LeoPrice.oldVal +incomeTVL2.oldVal*LeoPrice.oldVal }
                            decimal="." decimals={3}
                            end={LeoPrice.newVal * TVL.newVal / (10 ** 5) + liquidityTVL.newVal + incomeTVL.newVal * LeoPrice.newVal +incomeTVL2.newVal*LeoPrice.newVal }
                            useEasing={true} duration={1.2}
                        />
                    </Box>
                </Paper>
                <Paper width="300px" color="#FCF686" fontSize="20px" mb ="15px"style={{ cursor: 'pointer' }} hover={true}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href="" onClick={onClickItem('/staking')} target="_blank" style={{ color: '#6EF47A', textDecoration: 'none' }}>
                        Liquidity Node
                    </a>
                </Paper>
                <Box width="90%" display="flex" flexWrap={'wrap'}>
                    <Box display="flex" flex={1} mr="10px" mb="25px" mt ="5px" minWidth="150px">
                        <Paper width="100%" p="15px">
                            <Box fontSize="16px" lineHeight="22px">Node Yearly ROI</Box>
                            <Box fontSize="24px" fontWeight={"500"} color={liquidityRatio.newVal > 1 ? "#FCF686" : "#6EF47A"}>
                                <CountUp start={APR.oldVal * liquidityRatio.oldVal} decimal="." decimals={2} end={APR.newVal * liquidityRatio.newVal}
                                    useEasing={true} duration={1.2} />%
                            </Box>
                        </Paper>
                    </Box>
                    <Box display="flex" flex={1} mr="10px" mb="20px" minWidth="150px">
                        <Paper width="100%" p="15px">
                            <Box fontSize="16px" lineHeight="22px">Current Daily ROI</Box>
                            <Box fontSize="24px" fontWeight={"500"} color={liquidityRatio.newVal > 1 ? "#FCF686" : "#6EF47A"}>
                                <CountUp start={APR.oldVal * liquidityRatio.oldVal / 365} decimal="." decimals={2} end={APR.newVal * liquidityRatio.newVal / 365}
                                    useEasing={true} duration={1.2} />%
                            </Box>
                        </Paper>
                    </Box>
                    <Box display="flex" flex={1} mb="20px" mr="10px" minWidth="150px">
                        <Paper width="100%" p="15px">
                            <Box fontSize="16px" lineHeight="22px">TVL</Box>
                            <Box fontSize="24px" fontWeight={"500"} color="#FCF686">$
                                <CountUp start={roundWithPrecision(liquidityTVL.oldVal, 2)} decimal="." decimals={2} end={roundWithPrecision(liquidityTVL.newVal, 2)}
                                    useEasing={true} duration={1.2} />
                            </Box>
                        </Paper>
                    </Box>
                    <Box display="flex" flex={1} mb="20px" minWidth="150px">
                        <Paper width="100%" p="15px">
                            <Box fontSize="16px" lineHeight="22px" textAlign='center'>APR boost next milestone</Box>
                            <Box fontSize="24px" fontWeight={"500"} color="#6EF47A">$
                                <CountUp start={Math.ceil(liquidityTVL.oldVal/250000)*250000} decimal="." decimals={0} end={Math.ceil(liquidityTVL.newVal/250000)*250000}
                                    useEasing={true} duration={1.2} />
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Paper width="300px" color="#FCF686" fontSize="20px" mb="25px" mt ="5px" style={{ cursor: 'pointer' }} hover={true}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href="" onClick={onClickItem('/wrap')} target="_blank" style={{ color: '#6EF47A', textDecoration: 'none' }}>
                        xLEO wrap
                    </a>
                </Paper>
                <Box  width="90%" display="flex" flexWrap={'wrap'} justifyContent="space-between">
                    <Box display="flex" flex={1} mb="20px" minWidth="150px">
                        <Paper width="100%" p="15px">
                            <Box fontSize="16px" lineHeight="22px">xLEO vs LEO</Box>
                            <Box fontSize="24px" fontWeight={"500"} color="#FCF686">1 xLEO =&nbsp;
                            <CountUp start={ratio.oldVal/100000} decimal="." decimals={4} end={ratio.newVal/100000 }
                                    useEasing={true} duration={1.2} />&nbsp;LEO
                            </Box>
                        </Paper>
                    </Box>
                    <Box display="flex" flex={1} mb="20px" minWidth="150px">
                        <Paper width="100%" p="15px">
                            <Box fontSize="14px" lineHeight="26px">Total xLEO Burned</Box>
                            <Box fontSize="22px" fontWeight={"600"} lineHeight="40px" textAlign="center" color="#FCF686" >
                                <CountUp start={Burned.oldVal / (10 ** 5)} decimal="." decimals={2} end={Burned.newVal / (10 ** 5)}
                                    useEasing={true} duration={1.2} /> xLEO
                            </Box>
                            <Box fontSize="16px" lineHeight="26px" fontWeight="500">USD
                                <span style={{ color: '#FCF686' }}>$
                                    <CountUp start={LeoPrice.oldVal * Burned.oldVal * ratio.oldVal / (10 ** 10)} decimal="." decimals={2} end={LeoPrice.newVal * Burned.newVal * ratio.newVal / (10 ** 10)}
                                        useEasing={true} duration={1.2} />
                                </span>
                            </Box>
                        </Paper>
                    </Box>
                    <Box display="flex" flex={1} mb="20px" minWidth="150px">
                        <Paper width="100%" p="15px">
                            <Box fontSize="14px" lineHeight="26px">Total LEO staked</Box>
                            <Box fontSize="22px" fontWeight={"600"} lineHeight="40px" textAlign="center" color="#FCF686" >
                                <CountUp start={TVL.oldVal / (10 ** 5)} decimal="." decimals={2} end={TVL.newVal / (10 ** 5)}
                                    useEasing={true} duration={1.2} /> LEO
                            </Box>
                            <Box fontSize="16px" lineHeight="26px" fontWeight="500">USD
                                <span style={{ color: '#FCF686' }}>$
                                    <CountUp start={LeoPrice.oldVal * TVL.oldVal / (10 ** 5)} decimal="." decimals={2} end={LeoPrice.newVal * TVL.newVal / (10 ** 5)}
                                        useEasing={true} duration={1.2} />
                                </span>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Paper width="300px" color="#FCF686" fontSize="20px"  mb="25px" mt ="5px" style={{ cursor: 'pointer' }} hover={true}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href="" onClick={onClickItem('/income')} target="_blank" style={{ color: '#6EF47A', textDecoration: 'none' }}>
                        Income Generation
                    </a>
                </Paper>
                <Box mb="15px" width="90%" display="flex" flexWrap={'wrap'}>
                    <Box display="flex" flex={1} mr="10px" mb="20px" minWidth="190px">
                        <Paper width="100%" p="15px" textAlign={"center"}>
                            <Box fontSize="16px" lineHeight="22px">AVAX Max APR(locked 90 days on first deposit)</Box>
                            <Box fontSize="24px" fontWeight={"500"} color="#FCF686">
                                <CountUp start={AvaxPrice.oldVal * 45 * 365 * 100 / (total.oldVal * LeoPrice.oldVal) * 2} decimal="." decimals={2} end={AvaxPrice.newVal * 45 * 365 * 100 / (total.newVal * LeoPrice.newVal) * 2}
                                    useEasing={true} duration={1.2} />%
                            </Box>
                        </Paper>
                    </Box>

                    <Box display="flex" flex={1} mb="20px" mr="10px" minWidth="190px">
                        <Paper width="100%" p="15px">
                            <Box fontSize="16px" lineHeight="22px">AVAX Max Daily APR</Box>
                            <Box fontSize="24px" fontWeight={"500"} color={liquidityRatio.newVal > 1 ? "#FCF686" : "#6EF47A"}>
                                <CountUp start={AvaxPrice.oldVal * 45 * 100 * liquidityRatio.oldVal / (total.oldVal * LeoPrice.oldVal)} decimal="." decimals={2} end={AvaxPrice.newVal * 45 * 100 * liquidityRatio.newVal / (total.newVal * LeoPrice.newVal)}
                                    useEasing={true} duration={1.2} />%
                            </Box>
                        </Paper>
                    </Box>
                    <Box display="flex" flex={1} mb="20px" minWidth="190px">
                        <Paper width="100%" p="15px">
                            <Box fontSize="16px" lineHeight="22px">total TVL</Box>
                            <Box fontSize="24px" fontWeight={"500"} color="#FCF686">$
                                <CountUp start={roundWithPrecision(incomeTVL.oldVal * LeoPrice.oldVal +incomeTVL2.oldVal*LeoPrice.oldVal , 2)} decimal="." decimals={2} end={roundWithPrecision(incomeTVL.newVal * LeoPrice.newVal + incomeTVL2.newVal*LeoPrice.newVal, 2)}
                                    useEasing={true} duration={1.2} />
                            </Box>
                        </Paper>
                    </Box>
                    <Box display="flex" flex={1} mr="10px" mb="20px" minWidth="190px">
                        <Paper width="100%" p="15px" textAlign={"center"}>
                            <Box fontSize="16px" lineHeight="22px">ETH Max APR(locked 90 days on first deposit)</Box>
                            <Box fontSize="24px" fontWeight={"500"} color="#FCF686">
                                <CountUp start={ETHPrice.oldVal * 0.7 * 100 * 365 * liquidityRatio.oldVal / (total2.oldVal * LeoPrice.oldVal)} decimal="." decimals={2} end={ETHPrice.newVal *  0.7* 100 *365* liquidityRatio.newVal / (total2.newVal * LeoPrice.newVal)}
                                    useEasing={true} duration={1.2} />%
                            </Box>
                        </Paper>
                    </Box>

                    <Box display="flex" flex={1} mb="20px" mr="10px" minWidth="190px">
                        <Paper width="100%" p="15px">
                            <Box fontSize="16px" lineHeight="22px">ETH Max Daily APR</Box>
                            <Box fontSize="24px" fontWeight={"500"} color={liquidityRatio.newVal > 1 ? "#FCF686" : "#6EF47A"}>
                                <CountUp start={ETHPrice.oldVal * 0.7 * 100 * liquidityRatio.oldVal / (total2.oldVal * LeoPrice.oldVal)} decimal="." decimals={2} end={ETHPrice.newVal *  0.7* 100 * liquidityRatio.newVal / (total2.newVal * LeoPrice.newVal)}
                                    useEasing={true} duration={1.2} />%
                            </Box>
                        </Paper>
                    </Box>
                </Box>
            </Box>
        </Box>

    </Box>
}
export default DappDashboard;