import { Box } from "@mui/material";
import React, { FC } from "react";
import Distribution from "../Components/HomeComponent/Distribution";
import Footer from "../Components/HomeComponent/Footer";
import Guide from "../Components/HomeComponent/Guide";
import Main from "../Components/HomeComponent/Main";
import Protocal from "../Components/HomeComponent/Protocal";
import Tokenomic from "../Components/HomeComponent/Tokenomic";
import Topbar from "../Components/Topbar";

const Home: FC = () => {    
    return <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        overflow="auto"
        width="100%"
        // justifyContent="center"
    >
        <Topbar mode="home" />
        <Main />
        <Protocal />
        <Guide />
        <Tokenomic />
        <Distribution />
        <Footer />
        </Box>
}
export default Home;