import { Box } from "@mui/material";
import React from "react";
import medium from "../../assets/images/medium.svg"
import di from "../../assets/images/ds.svg"
import tw from "../../assets/images/tw.svg"
import logo from "../../assets/images/logo-large.png"

const Footer = () => {
    return <Box bgcolor="black" width="100%" >
        <Box my="40px" mx="100px" display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" flexDirection="column" color="#FCF686">
                <Box fontSize="20px" mb="40px">KEEP IN TOUCH</Box>
                <Box display="flex">
                    <a href="https://discord.gg/Wga5sWPRc6" rel="noreferrer"><Box component="img" width="20px" src={di} mr="30px" /></a>
                    <a href="https://twitter.com/LeonidasFinance" rel="noreferrer" target="_blank"><Box component="img" width="20px" src={tw} mr="30px" /></a>
                    <a href="https://medium.com/@leonidasfinance" rel="noreferrer" target="_blank"><Box component="img" width="20px" src={medium} mr="30px" /></a>
                </Box>
            </Box>
            <Box component="img" src={logo} width="100px" />
        </Box>
    </Box>
}

export default Footer;