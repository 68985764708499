import { Box } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import Topbar from "../Components/Topbar";
import DepositDialog from "../Components/DepositDialog";
import { getAllowance, getStakedAmountAndLockedPeriod, approve, getPendingLEO, getLPPrice, withdraw, getContract, getUserRemainLockTime, ABI } from "../hooks/useTokenInfo";
import useAuth from "../hooks/useAuth";
import Paper from "../Components/Paper";
import Sidebar from "../Components/Sidebar";
import CountUp from 'react-countup';
import { roundWithPrecision } from "../utils/calculation";
import coin2 from "../assets/images/coin2.png"
import logo from "../assets/images/logo-large.png"
import useMobile from "../hooks/useMobile";
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Bottombar from "../Components/Bottombar";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="standard" {...props} />;
});

const Staking: FC = () => {
    const [openDlg, setOpenDlg] = useState({ open: false, title: '' })
    const [openStakeWindow, setOpenStakeWindow] = useState(false)
    const [balance, setBalance] = useState({ oldVal: 0, newVal: 0 });
    const [claimableVal, setClaimableVal] = useState({ oldVal: 0, newVal: 0 })
    const { account } = useAuth()
    const [TVL, setTVL] = useState({ oldVal: 0, newVal: 0 })
    const [lpPrice, setLpPrice] = useState({ oldVal: 0, newVal: 0 })
    const [APR, setAPR] = useState({ oldVal: 0, newVal: 0 })
    const [LeoPrice, setLeoPrice] = useState({ oldVal: 0, newVal: 0 })
    const [timer, setTimer] = React.useState<any>({})
    const [period, setPeriod] = React.useState<number>(-1)
    const [contract, setContract] = useState<any>({})
    const [ratio, setRatio] = useState({ oldVal: 0, newVal: 0 })
    const [unlockTime, setUnlockTime] = React.useState<number>(0)
    const { isMobile } = useMobile()

    useEffect(() => {
        setBalance({ oldVal: 0, newVal: 0 })
        getLPPrice(0).then(({ _LPprice, _TVL, _APR, _LeoPrice }) => {
            setLpPrice({ oldVal: lpPrice.newVal, newVal: _LPprice })
            setTVL({ oldVal: TVL.newVal, newVal: _TVL })
            setAPR({ oldVal: APR.newVal, newVal: _APR })
            setLeoPrice({ oldVal: LeoPrice.newVal, newVal: _LeoPrice })

        });
        if (!account) return;
        getStakedAmountAndLockedPeriod(0, account).then(({ _balance, _ratio }) => {
            setBalance({ oldVal: balance.newVal, newVal: _balance })
            setRatio({ oldVal: 2, newVal: 2 })


        })
        getAllowance(0, account).then((isAllowance) => {
            if (isAllowance > 0) setOpenStakeWindow(true)
            else setOpenStakeWindow(false)
        })
        getPendingLEO(0, account).then((reward: number) => {
            setClaimableVal({ oldVal: claimableVal.newVal, newVal: reward / 100000 });
        })
        setPeriod(0);
        getContract(ABI.StakingABI).then((contract: any) => { setContract(contract) })
        getUserRemainLockTime(0, account).then((unlockTime: number) => {
            setUnlockTime(Number(unlockTime))
        })
        // eslint-disable-next-line
    }, [account])

    const getApprove = async () => {
        const res = await approve(0, account || undefined)
        if (res) setOpenStakeWindow(true)
    }

    React.useEffect(() => {
        clearTimeout(timer);
        const time = setTimeout(() => {
            if (period % 10 === 0) {
                getLPPrice(0).then(({ _LPprice, _TVL, _APR, _LeoPrice }) => {
                    setLpPrice({ oldVal: lpPrice.newVal, newVal: _LPprice })
                    setTVL({ oldVal: TVL.newVal, newVal: _TVL })
                    setAPR({ oldVal: APR.newVal, newVal: _APR })
                    setLeoPrice({ oldVal: LeoPrice.newVal, newVal: _LeoPrice })
                });
                if (account) {
                    getPendingLEO(0, account).then((reward: number) => {
                        setClaimableVal({ oldVal: claimableVal.newVal, newVal: reward / 100000 });
                    })
                    getUserRemainLockTime(0, account).then((unlockTime: number) => {
                        setUnlockTime(Number(unlockTime))
                    })
                    getStakedAmountAndLockedPeriod(0, account).then(({ _balance, _ratio }) => {
                        setBalance({ oldVal: balance.newVal, newVal: _balance })
                        setRatio({ oldVal: 2, newVal: 2 })

                    })
                }
            }
            if (account && unlockTime > 0) {
                setUnlockTime((period) => period - 1);
            }
            setPeriod((period) => period + 1);
        }, 1000);
        setTimer(time)
        // eslint-disable-next-line
    }, [period]);

    const claim = async () => {
        await withdraw(0, 0, account || "");
        contract.events.Withdraw({
            filter: { user: account, pid: 0 }, // Using an array means OR: e.g. 20 or 23
            // fromBlock: 0
        })
            .on('data', function (event: any) {
                setClaimableVal({ oldVal: claimableVal.newVal, newVal: 0 })
            })
            .on('changed', function (event: any) {
                // remove event from local database
            })
            .on('error', console.error);
    }

    const onEvnet = () => {
        if (!account) return;
        contract.events.allEvents({
            filter: { user: account, pid: 0 }, // Using an array means OR: e.g. 20 or 23
            // fromBlock: 0
        })
            .on('data', function (event: any) {
                console.log("evnet trigger", event)
                getStakedAmountAndLockedPeriod(0, account).then(({ _balance }) => {
                    setBalance({ oldVal: balance.newVal, newVal: _balance })
                })
                getPendingLEO(0, account).then((reward: number) => {
                    setClaimableVal({ oldVal: claimableVal.newVal, newVal: reward / 100000 });
                })
                getUserRemainLockTime(0, account).then((unlockTime: number) => {
                    setUnlockTime(Number(unlockTime))
                })
            })
            .on('changed', function (event: any) {
            })
            .on('error', console.error);
    }

    return <Box
        display="flex"
        flexDirection="column"
        width="100%"
        style={{ background: 'url(/image/bg4.png)', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat' }}
        zIndex="0"
    >
        <Topbar mode="staking" />
        <Box display="flex" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
            <Sidebar />
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="center"
                width="75%"
                m="auto"
                ml={isMobile ? "auto" : "300px"}
                mb="auto"
                zIndex="5"
            >
                <Box display="flex" mt="10px" justifyContent={"center"} alignItems="center" flexDirection="column">
                    <Alert severity="warning" sx={{ width: '100%', mb: '0px' }}>
                        <Box display="flex" justifyContent={"center"} alignItems="center">
                            Once provided liquidity, you cannot remove it. Your lp will become a NODE that prints you rewards forever!
                        </Box>
                    </Alert>
                </Box>
                <Box display="flex" justifyContent={"center"} alignItems="center" mt="30px" mb="30px" sx={{ flexDirection: { md: 'row', sm: 'column', xs: 'column' } }}>
                    <Paper p="15px" mr="20px" sx={{ mr: { md: '20px', sm: '0', xs: '0' }, mb: { md: '0', sm: '30px', xs: '30px' }, width: { md: 'auto', sm: '300px', xs: '300px' } }}>
                        <Box fontSize="16px" lineHeight="22px">Node Yearly ROI</Box>
                        <Box fontSize="24px" fontWeight={"500"} color={ratio.newVal > 1 ? "#FCF686" : "#6EF47A"}>
                            <CountUp start={APR.oldVal * ratio.oldVal} decimal="." decimals={2} end={APR.newVal * ratio.newVal}
                                useEasing={true} duration={1.2} />%
                        </Box>
                    </Paper>
                    <Paper p="15px" mr="20px" sx={{ mr: { md: '20px', sm: '0', xs: '0' }, mb: { md: '0', sm: '30px', xs: '30px' }, width: { md: 'auto', sm: '300px', xs: '300px' } }}>
                        <Box fontSize="16px" lineHeight="22px">Current Daily ROI</Box>
                        <Box fontSize="24px" fontWeight={"500"} color={ratio.newVal > 1 ? "#FCF686" : "#6EF47A"}>
                            <CountUp start={APR.oldVal * ratio.oldVal / 365} decimal="." decimals={2} end={APR.newVal * ratio.newVal / 365}
                                useEasing={true} duration={1.2} />%
                        </Box>
                    </Paper>
                    <Paper p="15px" mr="20px" sx={{ mr: { md: '20px', sm: '0', xs: '0' }, mb: { md: '0', sm: '30px', xs: '30px' }, width: { md: 'auto', sm: '300px', xs: '300px' } }}>
                        <Box fontSize="16px" lineHeight="22px">TVL</Box>
                        <Box fontSize="24px" fontWeight={"500"} color="#FCF686">$
                            <CountUp start={roundWithPrecision(TVL.oldVal, 2)} decimal="." decimals={2} end={roundWithPrecision(TVL.newVal, 2)}
                                useEasing={true} duration={1.2} />
                        </Box>
                    </Paper>
                    <Paper p="15px" sx={{ mr: { md: '20px', sm: '0', xs: '0' }, mb: { md: '0', sm: '30px', xs: '30px' }, width: { md: 'auto', sm: '300px', xs: '300px' } }}>
                        <Box fontSize="16px" lineHeight="22px" textAlign='center'>Next Milestone For ROI Boost</Box>
                        <Box fontSize="24px" fontWeight={"500"} color="#6EF47A">$
                            <CountUp start={Math.ceil(TVL.oldVal / 250000) * 250000} decimal="." decimals={0} end={Math.ceil(TVL.newVal / 250000) * 250000}
                                useEasing={true} duration={1.2} />
                        </Box>
                    </Paper>
                    <Paper p="15px" mr="20px" sx={{ mr: { md: '20px', sm: '0', xs: '0' }, mb: { md: '0', sm: '30px', xs: '30px' }, width: { md: 'auto', sm: '300px', xs: '300px' } }}>
                        <Box fontSize="16px" lineHeight="22px"> Daily ROI After Boost</Box>
                        <Box fontSize="24px" fontWeight={"500"} color= "#6EF47A">
                            <CountUp start={APR.oldVal * ratio.oldVal*1.5 / 365} decimal="." decimals={2} end={APR.newVal * ratio.newVal *1.5/ 365}
                                useEasing={true} duration={1.2} />%
                        </Box>
                    </Paper>
                </Box>

                <Box display="flex" mb="30px" mt="30px" justifyContent={"center"} alignItems="center" width="60%" sx={{ flexDirection: { md: 'row', sm: 'column-reverse', xs: 'column-reverse' } }}>
                    <Paper p="20px" sx={{ mr: { md: '20px', sm: '0', xs: '0' }, mb: { md: '0', sm: '50px', xs: '50px' }, width: { md: 'auto', sm: '300px', xs: '300px' } }} flex={1} position="relative">
                        <Paper p="7px" display="flex" left="50%" top="-17px" style={{ transform: 'translateX(-50%)' }} position="absolute" alignItems="center" justifyContent="center" bgcolor="lightgray">
                            <Box component="img" src={logo} width="20px" />
                        </Paper>
                        <Box fontSize="14px" lineHeight="26px">LEO EARNED</Box>
                        <Box fontSize="32px" fontWeight={"600"} lineHeight="50px" color="#FCF686" >
                            <CountUp start={claimableVal.oldVal} decimal="." decimals={4} end={claimableVal.newVal}
                                useEasing={true} duration={1.2} />
                        </Box>
                        <Box fontSize="16px" lineHeight="26px" fontWeight="500" mb="30px">USD
                            <span style={{ color: '#FCF686' }}>&nbsp; $
                                <CountUp start={(claimableVal.oldVal * LeoPrice.oldVal)} decimal="." decimals={4} end={claimableVal.newVal * LeoPrice.newVal}
                                    useEasing={true} duration={1.2} />
                            </span>
                        </Box>
                        <Paper width="100%" color="#FCF686" fontSize="24px" style={{ cursor: 'pointer' }} onClick={claim} hover={true}>CLAIM</Paper>
                    </Paper>
                    <Paper p="20px" sx={{ mr: { md: '20px', sm: '0', xs: '0' }, mb: { md: '0', sm: '50px', xs: '50px' }, width: { md: 'auto', sm: '300px', xs: '300px' } }} flex={1} position="relative">
                        <Box left="50%" top="-17px" style={{ transform: 'translateX(-50%)' }} position="absolute" display="flex" >
                            <Paper p="7px" ml="20x" bgcolor="lightgray">
                                <Box component="img" src={logo} width="20px" />
                            </Paper>
                            <Paper p="7px" mr="0px" bgcolor="lightgray">
                                <Box component="img" src={coin2} width="20px" />
                            </Paper>
                        </Box>

                        <Box fontSize="14px" lineHeight="26px">LEO-AVAX STAKED</Box>
                        <Box fontSize="32px" fontWeight={"600"} lineHeight="50px" color="#FCF686" >
                            <CountUp start={balance.oldVal} decimal="." decimals={8} end={balance.newVal}
                                useEasing={true} duration={1.2} />
                        </Box>
                        <Box fontSize="16px" lineHeight="26px" fontWeight="500" mb="30px">USD
                            <span style={{ color: '#FCF686' }}>$
                                <CountUp start={lpPrice.oldVal * balance.oldVal * 10 ** 18 * 116 / 100} decimal="." decimals={2} end={lpPrice.newVal * balance.newVal * 10 ** 18 * 116 / 100}
                                    useEasing={true} duration={1.2} />
                            </span>
                        </Box>
                        {!openStakeWindow ?
                            <Paper width="100%" color="#FCF686" fontSize="24px" style={{ cursor: 'pointer' }} hover={true} onClick={getApprove} >APPROVE</Paper> :
                            <Box display="flex" justifyContent="center" width="70%">
                                <Paper width="50px" onClick={() => { setOpenDlg({ open: true, title: 'Deposit' }) }} style={{ cursor: 'pointer' }} hover={true}>+</Paper>
                            </Box>
                        }
                    </Paper>
                </Box>
                <Paper width="300px" color="#FCF686" fontSize="20px" style={{ cursor: 'pointer' }} hover={true}>
                    <a href="https://traderjoexyz.com/pool/0x945a6869819cdAD404DC80647a2085957CB1f28b/AVAX" rel="noreferrer" target="_blank" style={{ color: 'white', textDecoration: 'none' }}>
                        Provide Liquidity
                    </a>
                </Paper>
                <Bottombar />
            </Box>

        </Box>
        <DepositDialog
            open={openDlg.open}
            title={openDlg.title}
            onClose={() => { setOpenDlg({ open: false, title: '' }) }}
            onEvent={onEvnet}
        />
    </Box>
}
export default Staking;