import { Box } from "@mui/material";
import React from "react";
import useMobile from "../../hooks/useMobile";
import Paper from "../Paper";

const Guide = () => {
    const {isMobile} = useMobile();
    return <Box style={{background: 'url(/image/bg3.png)', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat'}} width="100%" display="flex" justifyContent="center">
        <Box mt="50px" mb="100px" display="flex" justifyContent="center" flexDirection="column"  alignItems="center" mx="50px">
            <Box fontSize="30px" lineHeight="65px" textAlign={'center'}>LEONIDAS: HOW IT WORKS</Box>
            <Box mt="50px" display="flex" flexDirection={isMobile?'column':'row'}>
                <Paper px="20px" pt="22px" pb="40px" mr={isMobile?"0":"14px"} mb={isMobile?"40px":"0"} maxWidth="300px" justifyContent="start" >
                    <Box color="#6EF47A" mb="8px" fontSize="24px" fontWeight="500">$LEO Token</Box>
                    <Box fontSize="12px" fontWeight="500" textAlign="center">$LEO is the native token which interest rebase rewards are paid. Every token holder automatically receives 0.02355% interest every 15 minutes just for holding $LEO tokens in their own wallet!</Box>
                </Paper>
                <Paper px="20px=" pt="22px" pb="40px" maxWidth="300px" >
                    <Box color="#6EF47A" mb="20px" fontSize="24px" fontWeight="500">Liquidity Node</Box>
                    <Box fontSize="12px" fontWeight="500" textAlign="center">Users can earn more $LEO token with really high ROI by providing liquidity and lock forever, thus bootstrapping more liquidity for the protocol.</Box>
                </Paper>
            </Box>
            <Box mt="40px" display="flex" flexDirection={isMobile?'column':'row'}>
                <Paper px="20px" pt="22px" pb="40px" mr={isMobile?"0":"14px"} mb={isMobile?"40px":"0"} maxWidth="300px" justifyContent="start" >
                    <Box color="#6EF47A" mb="8px" fontSize="24px" fontWeight="500" textAlign={'center'}>Income Generation Pool(IGP))</Box>
                    <Box fontSize="12px" fontWeight="500" textAlign="center">The IGP pool accumulates the trading fees in Avax to distribute back to loyal holders of $LEO</Box>
                </Paper>
                <Paper px="20px" pt="22px" pb="23px" maxWidth="300px" mr={isMobile?"0":"14px"} mb={isMobile?"40px":"0"} justifyContent="start" >
                    <Box color="#6EF47A" mb="28px" fontSize="24px" fontWeight="500">$LEO Treasury</Box>
                    <Box fontSize="12px" fontWeight="500" textAlign="center">Income generation also comes from Treasury Investment, which will be also redistributed on top of IGP generation</Box>
                </Paper>
                <Paper px="20px" pt="22px" pb="23px" maxWidth="300px" justifyContent="start" >
                    <Box color="#6EF47A" mb="8px" fontSize="24px" fontWeight="500">The Fire Pit</Box>
                    <Box fontSize="12px" fontWeight="500" textAlign="center">2.5% of all $LEO traded are burnt in the Fire Pit. The more that is traded, the more get put into the fire causing the fire pit to grow in size reducing the circulating supply and keeping the LEO protocol stable.</Box>
                </Paper>
            </Box>
        </Box>
    </Box>
}

export default Guide;