/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty-pattern */
import Web3 from "web3";
import ERC20ABI from "../contracts/ERC20ABI.json";
import ContractABI from "../contracts/Contract.json";
import ContractABI2 from "../contracts/Contract2.json";
import LPtokenABI from "../contracts/LPtokenABI.json"
import PresaleABI from "../contracts/PresaleABI.json"
import xLEOABI from "../contracts/xLEOABI.json"
import { getTokenPrice, WAVAX_ID } from "./getDataFromApi";

declare let window: any;

export enum ABI { StakingABI, LPtokenABI, PresaleABI, XLeoABI }

const getPoolTokenAddr = async (_pid: number) => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const contract = await new window.web3.eth.Contract(ContractABI, process.env.REACT_APP_CONTRACT_ADDR2);
    const poolInfo = await contract.methods.poolInfo(_pid).call()
    return poolInfo.lpToken;
}

export const getContract = async (abi: number) => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    switch (abi) {
        case ABI.StakingABI:
            return await new window.web3.eth.Contract(ContractABI, process.env.REACT_APP_CONTRACT_ADDR2);
        case ABI.LPtokenABI:
            return await new window.web3.eth.Contract(LPtokenABI, process.env.REACT_APP_LEO_CONTRACT_ADDR);
        case ABI.PresaleABI:
            return await new window.web3.eth.Contract(PresaleABI, process.env.REACT_APP_PRESALE_CONTRACT_ADDR);
        case ABI.XLeoABI:
            return await new window.web3.eth.Contract(xLEOABI, process.env.REACT_APP_XLEO_CONTRACT_ADDR);
        default: return;
    }
}

export const getUserBalance = async (_pid: number, account: string) => {

    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const tokencontract = await new window.web3.eth.Contract(xLEOABI, await getPoolTokenAddr(_pid));
    const contract = await new window.web3.eth.Contract(ContractABI, process.env.REACT_APP_CONTRACT_ADDR2);
    const userInfo = await contract.methods.userInfo(_pid, account).call()
    const Decimal = await tokencontract.methods.decimals().call();
    const balance = await tokencontract.methods.balanceOf(account).call();
    return { balance: balance / Math.pow(10, Decimal), lockTime: userInfo.unlockTime };
}

export const getUserRemainLockTime = async (_pid: number, account: string) => {

    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const contract = await new window.web3.eth.Contract(ContractABI2, process.env.REACT_APP_CONTRACT_ADDR2);
    const remaining = await contract.methods.getRemainingLockedTime(_pid, account).call()
    return remaining;
}

export const approve = async (_pid: number, account?: string) => {
    if (!account) return;
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const tokencontract = await new window.web3.eth.Contract(xLEOABI, await getPoolTokenAddr(_pid));
    return await tokencontract.methods.approve(process.env.REACT_APP_CONTRACT_ADDR2, '0x99999999999999999999999999999').send({ from: account });
}

export const getAllowance = async (_pid: number, account: string) => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }

    const tokencontract = await new window.web3.eth.Contract(xLEOABI, await getPoolTokenAddr(_pid));
    return await tokencontract.methods.allowance(account, process.env.REACT_APP_CONTRACT_ADDR2).call();
}

export const getPendingAvax = async (_pid: number, account: string) => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const contract = await new window.web3.eth.Contract(ContractABI2, process.env.REACT_APP_CONTRACT_ADDR2);
    return await contract.methods.pendingReward(_pid, account).call();
}

export const deposit = async (_pid: number, amount: number, lockTime: number, account: string) => {

    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }

    const tokencontract = await new window.web3.eth.Contract(xLEOABI, await getPoolTokenAddr(_pid));
    const Decimal = await tokencontract.methods.decimals().call();
    // await tokencontract.methods.approve(process.env.REACT_APP_CONTRACT_ADDR2, '0x' + (Math.round(amount * Math.pow(10, Decimal))).toString(16)).send({ from: account });
    const contract = await new window.web3.eth.Contract(ContractABI2, process.env.REACT_APP_CONTRACT_ADDR2);
    contract.methods.deposit(_pid, '0x' + (Math.round(amount * Math.pow(10, Decimal))).toString(16), lockTime).send({ from: account });
}

export const getStakedAmountAndLockedPeriod = async (_pid: number, account: string) => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const contract = await new window.web3.eth.Contract(ContractABI2, process.env.REACT_APP_CONTRACT_ADDR2);
    const userInfo = await contract.methods.userInfo(_pid, account).call()
    const tokencontract = await new window.web3.eth.Contract(xLEOABI, await getPoolTokenAddr(_pid));
    const Decimal = await tokencontract.methods.decimals().call();

    return { _balance: userInfo.amount / Math.pow(10, Decimal), lockTime: userInfo.unlockTime, _ratio: userInfo.amount != 0 ? userInfo.userPoint / userInfo.amount : 1 };
}

export const withdraw = async (_pid: number, amount: number, account: string) => {

    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const tokencontract = await new window.web3.eth.Contract(xLEOABI, await getPoolTokenAddr(_pid));
    const Decimal = await tokencontract.methods.decimals().call();
    const contract = await new window.web3.eth.Contract(ContractABI2, process.env.REACT_APP_CONTRACT_ADDR2);
    contract.methods.withdraw(_pid, '0x' + (Math.round(amount * Math.pow(10, Decimal))).toString(16)).send({ from: account });
    return contract;
}

export const getLP = async (_pid: number) => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const AvaxPrice = await getTokenPrice(WAVAX_ID);
    const tokencontract = await new window.web3.eth.Contract(xLEOABI, await getPoolTokenAddr(_pid));
    const totalVal = await tokencontract.methods.LEOBalance(process.env.REACT_APP_CONTRACT_ADDR2).call();

    const contract = await new window.web3.eth.Contract(ContractABI2, process.env.REACT_APP_CONTRACT_ADDR2);
    const totPoint = await contract.methods.poolInfo(_pid).call();

    const totalP = totPoint.totalPoint;

    const TVL = totalVal;
    console.log(TVL);
    //console.log(APR);
    //tvl = total lp tokens staked in contract* lp token price
    return { _TVL: TVL/100000, _AvaxPrice: AvaxPrice , _total:totalP/1.3/100000}
}

export const getWAVAXAmount = async (account: string) => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
    }
    const WAVAX = await new window.web3.eth.Contract(ERC20ABI, "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7");
    const Decimal = await WAVAX.methods.decimals().call();
    const balance = await WAVAX.methods.balanceOf(account).call()
    return { balance: balance / Math.pow(10, Decimal) };
}








