import { Box, Grid } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import Topbar from "../Components/Topbar";
import useAuth from "../hooks/useAuth";
import LEO_ABI from '../contracts/LEO_ABI.json'
import Web3 from "web3";
import { getLPPrice } from "../hooks/useTokenInfo";
import Sidebar from "../Components/Sidebar";
import Paper from "../Components/Paper";
import CountUp from 'react-countup';
import useMobile from "../hooks/useMobile";

export const Yeild = 0.02355;
export const DayilROI = 0.0228;

declare let window: any;

const Account: FC = () => {
    const { account } = useAuth()
    const [LEOPrice, setLEOPrice] = useState({oldVal: 0, newVal: 0})
    const [lastUpdatedTime, setLastUpdatedTime] = useState<number>()
    const [balanceOf, setBalanceOf] = useState({oldVal: 0, newVal: 0})
    const [timer, setTimer] = useState<any>(0)
    const [period, setPeriod] = React.useState<number>(-1)
    const {isMobile} = useMobile()
    
    useEffect(() => {
        clearTimeout(timer);
        const time = setTimeout(() => {
            if (!lastUpdatedTime) return
            if (lastUpdatedTime < 2) {
                window.location.reload();
                return;
            }
            if(period%20 === 0) {
                init(false)
            }
            setLastUpdatedTime((timeLimit: any) => (timeLimit - 1));
            setPeriod((period) => period + 1);
        }, 1000);
        setTimer(time)
        // eslint-disable-next-line
    }, [lastUpdatedTime]);

    useEffect(() => {
        init(true)
        setPeriod(1);
        // eslint-disable-next-line
    }, [account])

    const init = async (timerFlag: boolean) => {
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum);
        }
        const LEO_contract = new window.web3.eth.Contract(LEO_ABI as any, process.env.REACT_APP_LEO_CONTRACT_ADDR);
        const dec = await LEO_contract.methods.DECIMALS().call();
        const lastRebasedTime = await LEO_contract.methods._lastRebasedTime().call();
        if(timerFlag) {
            let rebaseTime =  15*60 - (Math.floor(Date.now() / 1000) - lastRebasedTime)%(15*60)
            setLastUpdatedTime(rebaseTime)
        }
        setBalanceOf(account ? {oldVal: balanceOf.newVal, newVal:(await LEO_contract.methods.balanceOf(account).call()) / Math.pow(10, dec)} : {oldVal: 0, newVal: 0})
        setLEOPrice({oldVal: LEOPrice.newVal, newVal: (await getLPPrice(0))._LeoPrice})
    }
    return <Box
        display="flex"
        flexDirection="column"
        width="100%"
        style={{ background: 'url(/image/bg4.png)', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat' }}
        zIndex="0"
    >
        <Topbar mode="staking" />
        <Box display="flex" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
            <Sidebar />
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="center"
                width="75%"
                ml={isMobile ? "auto":"300px"}
                mr="auto"
                zIndex="5"
            >
                <Grid container mt="50px" mb="30px" width="75%" spacing={2}>
                    <Grid item md={4} sm={12} xs={12}>
                        <Paper p="20px" py="30px">
                            <Box mb="5px" fontSize="18px">YOUR BALANCE</Box>
                            <Box fontSize="24px" color="#FCF686">
                                <CountUp start={balanceOf.oldVal} decimal="." decimals={3} end={balanceOf.newVal}
                                    useEasing={true} duration={1.2} /> LEO
                            </Box>
                            <Box fontSize="16px" lineHeight="26px" fontWeight="500" mb="7px">USD 
                                <span style={{ }}>&nbsp; $
                                <CountUp start={balanceOf.oldVal * LEOPrice.oldVal } decimal="." decimals={4} end={balanceOf.newVal * LEOPrice.newVal}
                                    useEasing={true} duration={1.2} />
                                </span>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                        <Paper p="20px" py="30px">
                            <Box mb="5px" fontSize="18px">APY</Box>
                            <Box fontSize="24px" color="#FCF686">383,025.8%</Box>
                            <Box mb="5px"  fontSize="18px">Daily ROI 2.28%</Box>
                        </Paper>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                        <Paper p="10px" py="30px">
                            <Box mb="5px" fontSize="18px">Next Rebase:</Box>
                            <Box fontSize="24px" color="#FCF686">
                                {lastUpdatedTime && (~~((lastUpdatedTime - 1) / 3600) % 24).toLocaleString("en-US", {
                                    minimumIntegerDigits: 2,
                                    useGrouping: false,
                                })}
                                :
                                {lastUpdatedTime && (~~((lastUpdatedTime - 1) / 60) % 24).toLocaleString("en-US", {
                                    minimumIntegerDigits: 2,
                                    useGrouping: false,
                                })}
                                :
                                {lastUpdatedTime && (((lastUpdatedTime - 1) % 60).toLocaleString("en-US", {
                                    minimumIntegerDigits: 2,
                                    useGrouping: false,
                                }))}
                            </Box>
                            <Box mb="5px" fontSize="18px">Until earn</Box>
                        </Paper>
                    </Grid>
                </Grid>
                <Paper width="calc(73% - 40px)" p="20px">
                    <Box display="flex" justifyContent="space-between" width="100%" mb="10px">
                        <Box>Current LEO Price</Box>
                        <Box color="#FCF686">$
                            <CountUp start={LEOPrice.oldVal } decimal="." decimals={3} end={LEOPrice.newVal}
                                    useEasing={true} duration={1.2} />
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" width="100%" mb="10px">
                        <Box>Next Reward Amount</Box>
                        <Box color="#FCF686">
                            <CountUp start={balanceOf.oldVal * Yeild/100 } decimal="." decimals={3} end={balanceOf.newVal * Yeild/100}
                                    useEasing={true} duration={1.2} /> LEO
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" width="100%" mb="10px">
                        <Box>Next Reward Amount USD</Box>
                        <Box color="#FCF686">$
                            <CountUp start={balanceOf.oldVal *  DayilROI*LEOPrice.oldVal/100} decimal="." decimals={3} end={balanceOf.newVal * DayilROI*LEOPrice.newVal/100}
                                    useEasing={true} duration={1.2} />
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" width="100%" mb="10px">
                        <Box>Next Reward Yield</Box>
                        <Box color="#FCF686">0.02355%</Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" width="100%" mb="10px">
                        <Box>ROI(1-Day Rate) USD</Box>
                        <Box color="#FCF686">$
                            <CountUp start={balanceOf.oldVal * DayilROI*LEOPrice.oldVal} decimal="." decimals={3} end={balanceOf.newVal * DayilROI*LEOPrice.newVal}
                                    useEasing={true} duration={1.2} />
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" width="100%" mb="10px">
                        <Box>ROI(5-Day Rate)</Box>
                        <Box  color="#FCF686">11.96%</Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" width="100%" mb="10px">
                        <Box>ROI(5-Day Rate) USD</Box>
                        <Box color="#FCF686">$
                            <CountUp start={balanceOf.oldVal * (Math.pow((DayilROI +1), 5)-1)*LEOPrice.oldVal} decimal="." decimals={3} end={balanceOf.newVal * (Math.pow((DayilROI + 1), 5)-1)*LEOPrice.newVal}
                                    useEasing={true} duration={1.2} />
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </Box>

    </Box>
}
export default Account;