import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import Paper from "../Paper";
import useMobile from "../../hooks/useMobile";
import { getLPPrice } from "../../hooks/useTokenInfo";
import { numberWithCommas } from "../../utils/calculation";

const Protocal = () => {
    const [lpPrice, setLpPrice] = useState(0)
    const [APR, setAPR] = useState(0)
    const {isMobile} = useMobile();

    useEffect(() => {
        getLPPrice(0).then(({ _LPprice, _APR }) => {
            setLpPrice(_LPprice)
            setAPR(_APR)
        });
    }, [])

    return <Box style={{background: 'url(/image/bg2.png)', backgroundSize: '100% 100%'}} width="100%" display="flex" justifyContent="center">
        <Box mt="50px" mb="100px" display="flex" justifyContent="center" flexDirection="column"  alignItems="center" mx="50px">
            <Box color="#6EF47A" fontSize="30px" lineHeight="65px" textAlign="center">LEONIDAS: DEFI LIQUIDITY NODE + REBASE 2.0 PROTOCOL</Box>
            <Box mt="120px" display="flex" mb="90px" flexDirection={isMobile?'column':'row'}>
                <Paper px="35px" pt="50px" pb="31px" mr={isMobile?"0":"20px"} mb={isMobile?"40px":"0"} maxWidth="300px">
                    <Box color="#6EF47A" mb="50px" fontSize="24px" fontWeight="500">383,025.80%</Box>
                    <Box fontSize="12px" fontWeight="500">Fixed Staking APY</Box>
                </Paper>
                <Paper px="35px" pt="50px" pb="31px" mr={isMobile?"0":"20px"} mb={isMobile?"40px":"0"} maxWidth="300px">
                    <Box color="#6EF47A" mb="50px" fontSize="24px" fontWeight="500">{lpPrice > 0 ? numberWithCommas(APR*2, 4) : 0}%</Box>
                    <Box fontSize="12px" fontWeight="500">Liquidity NODE Yearly ROI</Box>
                </Paper>
                <Box display="flex" flexDirection="column" maxWidth="300px">
                    <Box fontSize="24px">ABOUT</Box>
                    <Box fontSize="12px" fontWeight="500">Leonidas provides a decentralized financial asset which rewards users with a sustainable fixed compound interest model. On top of that, users can earn by locking liquidity into the liquidity NODE to earn high ROI. There is also a profit generation system to bring income to xLEO holders.</Box>
                </Box>
            </Box>
            <Paper px={isMobile?"20px":"100px"} py="15px" textAlign="center" style={{wordBreak: 'break-word'}}>
                $LEO Contract: 0x945a6869819cdAD404DC80647a2085957CB1f28b
            </Paper>
        </Box>
    </Box>
}

export default Protocal;