import { Box, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import Topbar from "../Components/Topbar";
import { NumberFormat } from "../hooks/getDataFromApi";
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { DayilROI } from "./Account";
import useAuth from "../hooks/useAuth";
import LEO_ABI from '../contracts/LEO_ABI.json'
import Web3 from "web3";
import { getLPPrice } from "../hooks/useTokenInfo";
import Sidebar from "../Components/Sidebar";
import Paper from "../Components/Paper";
import useMobile from "../hooks/useMobile";

const PrettoSlider = styled(Slider)({
    color: '#FCF686',
    height: 8,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#FCF686',
        color: 'black',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});

const CustomInput = styled((props: any) => <input {...props} />)`
    border-radius: 10px;
    border: 2px solid #6EF47A;
    padding: 10px;
    color: white;
    background-color: transparent;
    width: 100px;
    &:focus-visible{
        outline: none;
        border: none;
    }
`

declare let window: any;

const Calculator: FC = () => {
    const [LEOPrice, setLEOPrice] = useState(0)
    const [inputVal, setInputVal] = useState("")
    const [LEOfuturePrice, setFuturePrice] = useState("")
    const [LEObuyPrice, setbuyPrice] = useState("")
    const [LEOBalance, setLEOBalance] = useState(0)
    const [days, setDays] = useState(0)
    const { account } = useAuth()
    const {isMobile} = useMobile()

    useEffect(() => {
        init()
        // eslint-disable-next-line
    }, [account])

    const init = async () => {
        setLEOPrice((await getLPPrice(0))._LeoPrice)
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum);
        }
        const LEO_contract = new window.web3.eth.Contract(LEO_ABI as any, process.env.REACT_APP_LEO_CONTRACT_ADDR);
        const dec = await LEO_contract.methods.DECIMALS().call();
        setLEOBalance(account ? (await LEO_contract.methods.balanceOf(account).call()) / Math.pow(10, dec) : 0)
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputVal(event.target.value);
    };

    const handleFuturePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFuturePrice(event.target.value);
    };

    const handleBuyPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
        setbuyPrice(event.target.value);
    };
    const handleSlider = (event: any, newValue: any) => {
        setDays(newValue);
    };

    return <Box
        display="flex"
        flexDirection="column"
        width="100%"
        style={{ background: 'url(/image/bg4.png)', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat' }}
        zIndex="0"
    >
        <Topbar mode="staking" />
        <Box display="flex" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
            <Sidebar />
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="center"
                width="75%"
                ml={isMobile ? "auto":"300px"}
                mr="auto"
                zIndex="5"
            >
                <Paper mt="50px" mb="30px" width="70%" p="20px" px="40px"justifyContent={'center'} alignItems="center" >
                    <Box justifyContent={'center'} alignItems="center"  fontSize="30px" color="#6EF47A">
                        CALCULATOR
                    </Box>
                    <Box justifyContent={'center'} alignItems="center" fontSize="18px" mb="30px">
                        Estimate your returns
                    </Box>
                    <Box display="flex" flexWrap={'wrap'} width="100%" mb="30px" justifyContent="center">
                        <Box display="flex" mb="10px" flexDirection="column" justifyContent={'center'} alignItems="center" width="33%" minWidth="150px">
                            <Box color="hsla(0,0%,100%,.6)" mb="5px">LEO Price</Box>
                            <Box fontWeight="bold">${NumberFormat(LEOPrice)}</Box>
                        </Box>
                        <Box display="flex" mb="10px" flexDirection="column" justifyContent={'center'} alignItems="center" width="33%" minWidth="150px">
                            <Box color="hsla(0,0%,100%,.6)" mb="5px">Current APY</Box>
                            <Box fontWeight="bold">
                                383,025.8%
                            </Box>
                        </Box>
                        <Box display="flex" mb="10px" flexDirection="column" justifyContent={'center'} alignItems="center" width="33%" minWidth="150px">
                            <Box color="hsla(0,0%,100%,.6)" mb="5px">Your LEO Balance</Box>
                            <Box fontWeight="bold">{NumberFormat(LEOBalance)}&nbsp;LEO</Box>
                        </Box>
                    </Box>
                    <Box display="flex" mb="15px" width="100%" flexWrap={'wrap'}>
                        <Box flex={1} mr="1vw" display="flex" flexDirection="column" alignItems="center">
                            <Box display="flex" flexDirection="column">
                                <Box fontSize="12px" fontWeight="400" mb="5px" ml="5px" >LEO Amount</Box>
                                <CustomInput
                                    value={inputVal}
                                    onChange={handleChange}
                                />
                            </Box>
                        </Box>
                        <Box flex={1} mr="1vw" display="flex" flexDirection="column" alignItems="center">
                            <Box display="flex" flexDirection="column">
                                <Box fontSize="12px" fontWeight="400" mb="5px" ml="5px" >APY%</Box>
                                <CustomInput
                                    value={"383025.8"}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" mb="30px" width="100%"  flexWrap={'wrap'}>
                        <Box flex={1} mr="1vw" display="flex" flexDirection="column" alignItems="center">
                            <Box display="flex" flexDirection="column" ml="15px">
                                <Box fontSize="12px" fontWeight="400" mb="5px" >LEO Price at Purchase($)</Box>
                                <CustomInput
                                    value={LEObuyPrice}
                                    onChange={handleBuyPrice}
                                />
                            </Box>
                            
                        </Box>
                        <Box flex={1} mr="1vw" display="flex" flexDirection="column" alignItems="center">
                            <Box display="flex" flexDirection="column">
                                <Box fontSize="12px" fontWeight="400" mb="5px" ml="5px" >LEO Future Price($)</Box>
                                <CustomInput
                                    value={LEOfuturePrice}
                                    onChange={handleFuturePrice}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ m: 0 }} />
                    <Typography gutterBottom>{days} days</Typography>
                    <PrettoSlider
                        max={365}
                        valueLabelDisplay="auto"
                        aria-label="pretto slider"
                        value={days}
                        onChange={handleSlider}
                    />

                    <Box width="100%" display="flex" fontSize="16px" flexDirection="column">
                        <Box display="flex" justifyContent="space-between">
                            <Box>Your initial investment</Box>
                            <Box>${Number(inputVal) ? NumberFormat(Number(inputVal) * Number(LEObuyPrice)) : 0}</Box>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <Box>Current wealth</Box>
                            <Box>${Number(inputVal) ? NumberFormat(Number(inputVal) * Number(LEObuyPrice)) : 0}</Box>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <Box>LEO rewards estimation</Box>
                            <Box>{Number(inputVal) ? NumberFormat(Number(inputVal) * Math.pow((DayilROI+1), days)) : 0} LEO</Box>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <Box>Potential return</Box>
                            <Box>${Number(inputVal) ? NumberFormat(Number(inputVal) * Math.pow((DayilROI+1), days)*Number(LEOfuturePrice)) : 0}</Box>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </Box>


    </Box>
}
export default Calculator;