import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import useAuth from '../hooks/useAuth';
import { depositLEO, burnxLEO, getUserLEOBalance, LEOBalance, getAllowanceLEO, getAllowancexLEO, approvexLEO, approveLEO, xLEOForLEO,LEOForxLEO} from "../hooks/xLEOinfo"
import { toFixed } from '../utils/calculation';
import Paper from './Paper';

interface DepositDialogProps {
    open: boolean;
    onClose: () => void;
    title: string;
    onEvent?: () => void;
}

const CustomInput = styled((props: any) => <input {...props} />)`
    border: none;
    height: 1.8vw;
    font-size: 1vw;
    background: transparent;
    color: white;
    &:focus-visible{
        outline: none;
        border: none;
    }
`

export default function WrapDialog(props: DepositDialogProps) {
    const [open, setOpen] = React.useState(false);
    const { account } = useAuth()
    const [balance, setBalance] = React.useState(0);
    const [StakedBalance, setStakedBalance] = React.useState(0);
    const [amount, setAmount] = React.useState("");
    const [withdrawAmount, setWithdrawAmount] = React.useState(0);
    const [showApproveButton, setShowApproveButton] = React.useState(true);
    const [displayVal, setDisplayVal] = React.useState(0)

    React.useEffect(() => {
        setOpen(props.open)
        setShowApproveButton(true)
        setWithdrawAmount(0)
        if(props.open) {
            setAmount("0")
            setBalance(0)
            if(!account) return;
            if(props.title === "Wrap") {
                getUserLEOBalance(0, account).then(({_balance})=>{
                    setBalance(_balance)
                    getAllowanceLEO(0, account, process.env.REACT_APP_XLEO_CONTRACT_ADDR||"").then((bal: number)=>{
                        if(bal> _balance) setShowApproveButton(false)
                        else setShowApproveButton(true)
                    })
                })
                
            } else {
                LEOBalance(account).then(async({_balance}) => {
                    setStakedBalance(_balance)
                    getAllowancexLEO(0, account, process.env.REACT_APP_XLEO_CONTRACT_ADDR||"").then((bal: number)=>{
                        if(bal> _balance) setShowApproveButton(false)
                        else setShowApproveButton(true)
                    })
                })
                
            }
        }
        // eslint-disable-next-line
    }, [props.open])

    React.useEffect(()=>{
        xLEOForLEO(StakedBalance).then((bal:number)=>{
            setDisplayVal(StakedBalance/10**5);
        })
        // eslint-disable-next-line
    }, [StakedBalance])

    const handleClose = () => {
        props.onClose();
        setOpen(false);
    };

    const depositOrWithdraw = async () => {
        if (!account) return;
        const val = parseFloat(amount)
        if(!val) {
            alert("Invalid amount")
            return;
        }
        if(props.title === "Wrap") {
            await depositLEO(val, account)
        } else {
            await burnxLEO(withdrawAmount, account);
        }
        props.onEvent&&props.onEvent()
        handleClose()
    }

    const changeAmount = (e: any) => {
        setAmount(e.target.value)
    }

    const setAmountPercentage = (percentage: number) => async() => {
        if(props.title === "Wrap") {
            setAmount(""+(balance * percentage / 100))
        } else {
            const displayVal = StakedBalance*percentage /100;
            setWithdrawAmount((await LEOForxLEO(StakedBalance)) * percentage / 100 / 10 ** 5)
            setAmount(""+(displayVal / 10 ** 5))
        }
    }

    const approve = async() => {
        let res;
        if(props.title === "Wrap") {
            res = await approveLEO(0, account||"", process.env.REACT_APP_XLEO_CONTRACT_ADDR||"");
        } else {
            res = await approvexLEO(0, account||"", process.env.REACT_APP_XLEO_CONTRACT_ADDR||"");
        }
        if(res.status) setShowApproveButton(false)
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                      background: 'url(/image/bg3.png)',
                      backgroundSize: '100% 100%',
                      backgroundRepeat: 'no-repeat',
                      color: 'white'
                    },
                }}
            >
                <Box fontSize="2vw" fontWeight="500" mt="2vw" px="2vw" pt="1vw" style={{textTransform: 'uppercase'}}>
                    {props.title}
                </Box>
                <Box display="flex" flexDirection="column" px="2vw" pt="2vw" pb="4vw">
                    <Box display="flex" mt="1vw" alignItems="flex-start" flexDirection="column" width="100%">
                        <Box flex={1} display="flex" flexDirection="column" mb="3vw" width="100%">
                            <Box fontSize="12px" mb="1vw" color="#C9C9C9">Amount</Box>
                            <Box borderBottom=" 1px solid white" display="flex" justifyContent="space-between">
                                <CustomInput value={amount} onChange={changeAmount} />
                                <Box color="white" display="flex" mb="0.5vw" >
                                    <Paper p="3px" mr="0.4vw" hover={true}><Button color="inherit" onClick={setAmountPercentage(25)} variant="text" sx={{ borderRadius: '5px', textTransform: 'none', fontSize: '0.6vw', p: '0.3vw', minWidth: '1vw' }}>25%</Button></Paper>
                                    <Paper p="3px" mr="0.4vw" hover={true}><Button color="inherit" onClick={setAmountPercentage(50)} variant="text" sx={{ borderRadius: '5px', textTransform: 'none', fontSize: '0.6vw', p: '0.3vw', minWidth: '1vw' }}>50%</Button></Paper>
                                    <Paper p="3px" mr="0.4vw" hover={true}><Button color="inherit" onClick={setAmountPercentage(75)} variant="text" sx={{ borderRadius: '5px', textTransform: 'none', fontSize: '0.6vw', p: '0.3vw', minWidth: '1vw' }}>75%</Button></Paper>
                                    <Paper p="3px" hover={true}><Button color="inherit" onClick={setAmountPercentage(100)} variant="text" sx={{ borderRadius: '5px', textTransform: 'none', fontSize: '0.6vw', p: '0.3vw', minWidth: '1vw' }}>MAX</Button></Paper>
                                </Box>
                            </Box>
                            <Box fontSize="12px" mt="1vw" textAlign="right" color="#C9C9C9">{props.title==="Wrap" ? "Available" : "Staked balance"}&nbsp; 
                                {props.title==="Wrap" ?
                                <span style={{ color: '#ED4529' }}>{Number(balance) ? toFixed(Number(balance)): 0}</span> : 
                                <span style={{ color: '#ED4529' }}>{displayVal}</span> }
                            </Box>
                        </Box>
                    </Box>
                    
                    <Box display="flex" width="50%">
                        <Paper color="#6EF47A" width="100%" height="100%" mr="1vw" hover={true}>
                        {!showApproveButton ? <Button
                                color="inherit"
                                fullWidth
                                variant="text"
                                onClick={depositOrWithdraw}
                                sx={{ borderRadius: '25px', height: '80%' }}
                                disabled={amount === '0'|| !amount }
                            >{props.title}</Button>
                            :
                            <Button
                                color="inherit"
                                fullWidth
                                variant="text"
                                onClick={approve}
                                sx={{ borderRadius: '25px', height: '80%' }}
                            >{"Approve"}</Button>}
                        </Paper>
                        <Paper color="#ED4529" width="100%" height="100%" hover={true}>
                            <Button color="inherit" fullWidth variant="text" onClick={handleClose} sx={{ borderRadius: '25px', height: '80%'}}>CANCEL</Button>
                        </Paper>
                    </Box>
                </Box>
            </Dialog>
        </div>
    );
}