import { Box } from "@mui/material";
import React from "react";
import Paper from "../Paper";

const Tokenomic = () => {
    return <Box style={{background: 'url(/image/bg3.png)', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat'}} width="100%" display="flex" justifyContent="center">
        <Box mt="86px" mb="100px" display="flex" justifyContent="center" width="100%" flexDirection="column"  alignItems="center" >
            <Box fontSize="30px" lineHeight="65px" mb="25px">TOKENOMICS</Box>
            <Box width="100%" display="flex">
                <Box flex={1} display="flex" justifyContent="center">
                    <Box width="50%" display="flex" flexDirection="column" alignItems="flex-end">
                        <Paper px="20px" py="8px" mr="20px" mb="30px" width="fit-content">
                            <Box fontSize={"24px"} color="#6EF47A">BUY</Box>
                            <Box fontSize={"14px"}>14% Slippage</Box>
                        </Paper>
                        <Box display="flex" flexDirection="column" alignItems="flex-end" fontSize="14px">
                            <Box display="flex" flexDirection="column" alignItems="flex-end" mb="12px">
                                <Box>LEO Income Generation Pool</Box>
                                <Box>5% of order fees are stored in IGP</Box>
                            </Box>
                            <Box display="flex" flexDirection="column" alignItems="flex-end" mb="12px">
                                <Box>Treasury</Box>
                                <Box>6.5% of order fees go to the treasury</Box>
                            </Box>
                            <Box display="flex" flexDirection="column" alignItems="flex-end" mb="12px">
                                <Box>Fire Pit</Box>
                                <Box>2.5% of order fees return to liquidity</Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box flex={1} display="flex" justifyContent="center">
                    <Box width="50%" display="flex" flexDirection="column" >
                        <Paper px="20px" py="8px" ml="20px" mb="30px" width="fit-content">
                            <Box fontSize={"24px"} color="#6EF47A">SELL</Box>
                            <Box fontSize={"14px"}>16% Slippage</Box>
                        </Paper>
                        <Box display="flex" flexDirection="column" fontSize="14px">
                            <Box display="flex" flexDirection="column" mb="12px">
                                <Box>LEO Income Generation Pool</Box>
                                <Box>5% of order fees are stored in IGP</Box>
                            </Box>
                            <Box display="flex" flexDirection="column" mb="12px">
                                <Box>Treasury</Box>
                                <Box>8.5% of order fees go to the treasury</Box>
                            </Box>
                            <Box display="flex" flexDirection="column" mb="12px">
                                <Box>Fire Pit</Box>
                                <Box>2.5% of order fees return to liquidity</Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    </Box>
}

export default Tokenomic;